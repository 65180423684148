import React, {useEffect, useState} from 'react';
import TextfieldNumber from "../textfields/textfield.number";
import TextfieldAbattement from "../textfields/textfield.abattement";
import {styles} from "../../stylesheets/simulateurs.css";
import SelectCommon from "../selects/select.common";
import ButtonYesno from "../buttons/button.yesno.js";
import {useDispatch} from 'react-redux';
import {setDMTGObject} from "../../actions/dmtg.action";
import { ToolTipPerso } from '../selects/tooltip_perso';

const Donations_immo = (props) => {
        const dispatch = useDispatch();

        // Ici props contient donc :
        // props.resultKey => key du tableau "results" nécessaire pour setter la bonne valeur
        // props.functionSetResults => pour lancer la fonction du main depuis le component
        // ----

        // States avec les différentes valeurs à stocker
        // Possibilité également de mettre tous les states (value1, value2....) dans un seul state sous format:
        //
        // state: {
        //      value1: {},
        //      value2: {},
        //      ...
        // }
        //
        // Dans ce cas penser à changer le callback du useEffect qui prend plus bas [value1, value2]
        // ---

        const [lienParente, setLien] = useState(0)
        //const [nomDonataire, setNomDonataire] = useState(0)
        const [handicap, setHandicap] = useState(0)
        const [montantDonation, setMontantDonation] = useState(0)
        const [abattementLegal, setAbattementLegal] = useState(0)
        const [montantUtilise, setMontantUtilise] = useState(0)

        const handleChange = async (setter, value) => {
            setter(value)
            setInRedux(setter, value)
        };

        function setInRedux(setter, value) {
            let object = {"simulateurid": props.simulateurid, "inputs": {}};
            if (setter === setLien) {
                Object.assign(object['inputs'], {'lien_parente_donation': parseInt(value)})
                Object.assign(object['inputs'], {'handicap': parseInt(handicap)})
                Object.assign(object['inputs'], {'montant_donation': parseInt(montantDonation)})
                Object.assign(object['inputs'], {'montant_utilise': parseInt(montantUtilise)})
                Object.assign(object['inputs'], {'donateur': parseInt(props.donateur)})
                Object.assign(object['inputs'], {'donataire': parseInt(props.soloResultKey + 1)})
            }

            if (setter === setHandicap) {
                if (value === 1 && lienParente === "1") {
                    handleChange(setLien, "1")
                }
                Object.assign(object['inputs'], {'lien_parente_donation': parseInt(lienParente)})
                Object.assign(object['inputs'], {'handicap': parseInt(value)})
                Object.assign(object['inputs'], {'montant_donation': parseInt(montantDonation)})
                Object.assign(object['inputs'], {'montant_utilise': parseInt(montantUtilise)})
                Object.assign(object['inputs'], {'donateur': parseInt(props.donateur)})
                Object.assign(object['inputs'], {'donataire': parseInt(props.soloResultKey + 1)})
            }

            if (setter === setMontantDonation) {
                Object.assign(object['inputs'], {'lien_parente_donation': parseInt(lienParente)})
                Object.assign(object['inputs'], {'handicap': parseInt(handicap)})
                Object.assign(object['inputs'], {'montant_donation': parseInt(value)})
                Object.assign(object['inputs'], {'montant_utilise': parseInt(montantUtilise)})
                Object.assign(object['inputs'], {'donateur': parseInt(props.donateur)})
                Object.assign(object['inputs'], {'donataire': parseInt(props.soloResultKey + 1)})
            }

            if (setter === setMontantUtilise) {
                Object.assign(object['inputs'], {'lien_parente_donation': parseInt(lienParente)})
                Object.assign(object['inputs'], {'handicap': parseInt(handicap)})
                Object.assign(object['inputs'], {'montant_donation': parseInt(montantDonation)})
                Object.assign(object['inputs'], {'montant_utilise': parseInt(value)})
                Object.assign(object['inputs'], {'donateur': parseInt(props.donateur)})
                Object.assign(object['inputs'], {'donataire': parseInt(props.soloResultKey + 1)})
            }

/*          if (setter === setNomDonataire) {
                props.functionSetDonatairesList(props.soloResultKey, {"nom": value})
            }*/
            props.functionSetSoloResults(props.soloResultKey, object)
            dispatch(setDMTGObject(object));
        }

        const lienDonation = [
            {
                id: 1,
                name: "Entre époux ou entre partenaires pacsés"
            },
            {
                id: 2,
                name: "En ligne directe - Ascendants ou enfants"
            },
            {
                id: 3,
                name: "Entre frères et sœurs"
            },
            {
                id: 4,
                name: "Aux neveux et nièces"
            },
            {
                id: 5,
                name: "Aux petits enfants"
            },
            {
                id: 6,
                name: "Aux arrières petits enfants"
            },
            {
                id: 7,
                name: "Entre parents au-dela du 4ème degré et non parents"
            }]

        // Hook appelé à la génération de la page car aucune restriction n'est imposé aux fields value1 et value2.
        // Ce hook captera chaque modification de field et appelera la fonction du App.js "FunctionSetResults"
        // pour set la key correspondante à l'appel de component dans le state "results".

        useEffect(() => {
            if (lienParente === "1" && handicap === 1) {
                setAbattementLegal("240 049");
            } else if (lienParente === "1") {
                setAbattementLegal("80 724");
            } else if (lienParente === "2" && handicap === 1) {
                setAbattementLegal("259 325");
            } else if (lienParente === "2") {
                setAbattementLegal("100 000");
            } else if (lienParente === "3" && handicap === 1) {
                setAbattementLegal("175 257");
            } else if (lienParente === "3") {
                setAbattementLegal("15 932");
            } else if (lienParente === "4" && handicap === 1) {
                setAbattementLegal("167 286");
            } else if (lienParente === "4") {
                setAbattementLegal("7 967");
            } else if (lienParente === "5" && handicap === 1) {
                setAbattementLegal("191 190");
            } else if (lienParente === "5") {
                setAbattementLegal("31 865");
            } else if (lienParente === "6" && handicap === 1) {
                setAbattementLegal("164 635");
            } else if (lienParente === "6") {
                setAbattementLegal("5 310");
            } else if (lienParente === "7" && handicap === 1) {
                setAbattementLegal("159 325");
            } else if (lienParente === "7") {
                setAbattementLegal("0");
            }
        }, [lienParente, handicap]);

        return (
            <div style={styles.cardFlexContainer}>
                {/*<div>
                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            Indiquer le nom du donataire
                        </div>
                        <TextfieldNumber
                            handleChange={handleChange}
                            type={"text"}
                            placeholder={"Indiquer le nom"}
                            setter={setNomDonataire}
                        />
                    </div>
                </div>*/}

                <div style={styles.cardTitleDonataire}>
                    Donataire {props.soloResultKey + 1}
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Quel est leur lien de parenté ?
                    </div>
                    <SelectCommon items={lienDonation} defaultValue={"1"} nameField={"name"} optionValue={"id"}
                                  handleChange={handleChange} setter={setLien}/>
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Le bénéficiaire est-il en situation de handicap ?
                        <ToolTipPerso title={"En cas d’invalidité permanente, abattement de 159 325 € cumulable."} />
                    </div>
                    <ButtonYesno handleChange={handleChange} setter={setHandicap} defaultValue={0}/>
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Montant de la donation lui revenant
                        <ToolTipPerso title={"S'il s'agit d'une donation avec réserve d'usufruit, merci d'indiquer la part donnée en nue-propriété."} />
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer le montant"}
                        setter={setMontantDonation}
                    />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Abattement légal
                    </div>
                    <TextfieldAbattement
                        placeholder={abattementLegal + " €"}
                    />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Part de l’abattement légal déjà utilisé
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer le montant"}
                        setter={setMontantUtilise}
                    />
                </div>

            </div>
        );
    }
;

export default Donations_immo;
