import React, { useEffect, useState } from "react";
import { setSimulateurObject } from "../../../actions/simulateur.action";
import { useDispatch } from 'react-redux';
import { styles } from "../../../stylesheets/simulateurs.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import SelectDepartement from "../../../components/selects/select.departement";
import { useSnackbar } from 'notistack';
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";

// simulateur Id 28

function Simulateur_A1_28(props, simulateur, isParent, departements) {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    let currUser = JSON.parse(localStorage.getItem("user"));
    let currUserDep = 0
    if (currUser.societe_zip.startsWith("97")) {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 3);
        currUserDep = parseInt(trimedZip)
    } else {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 2);
        currUserDep = parseInt(trimedZip)
    }

    const [departement, setDepartement] = useState(0)
    const [montant_loyer, setMontant] = useState(0)
    const [duree, setDuree] = useState(0)
    const [valeurResiduelle, setValeurResiduelle] = useState(0)
    const [page, setPage] = useState(0)

    const handleChange = async (setter, value) => {
        if (setter === setDepartement) {
            setDepartement(parseInt(value))
        } else setter(value);
        setInRedux(setter, value)
    };

    const handleChangeRemarque = async (value) => {
        localStorage.setItem("remarque", value)
    }

    function setInRedux(setter, value) {
        if (props.isParent) {

            let object = {
                "simulateurid": props.simulateur.id,
                "departementid": 0,
                "inputs": {}
            };

            if (setter === setDepartement) {
                Object.assign(object['inputs'], { 'montant_loyer': parseInt(montant_loyer) })
                Object.assign(object['inputs'], { 'duree_bail': parseInt(duree) })
                Object.assign(object['inputs'], { 'valeur_residuelle': parseInt(valeurResiduelle) })
                Object.assign(object['inputs'], { 'nombre_page_copie': parseInt(page) })
                object.departementid = parseInt(value)
            }

            if (setter === setMontant) {
                Object.assign(object['inputs'], { 'montant_loyer': parseInt(value) })
                Object.assign(object['inputs'], { 'duree_bail': parseInt(duree) })
                Object.assign(object['inputs'], { 'valeur_residuelle': parseInt(valeurResiduelle) })
                Object.assign(object['inputs'], { 'nombre_page_copie': parseInt(page) })
                object.departementid = parseInt(departement)
            }

            if (setter === setDuree) {
                if (value < 18) {
                    enqueueSnackbar('Les baux emphytéotiques commencent à partir de 18 ans', {
                        variant: 'error'
                    });
                    return;
                }
                Object.assign(object['inputs'], { 'montant_loyer': parseInt(montant_loyer) })
                Object.assign(object['inputs'], { 'duree_bail': parseInt(value) })
                Object.assign(object['inputs'], { 'valeur_residuelle': parseInt(valeurResiduelle) })
                Object.assign(object['inputs'], { 'nombre_page_copie': parseInt(page) })
                object.departementid = parseInt(departement)
            }

            if (setter === setValeurResiduelle) {
                Object.assign(object['inputs'], { 'montant_loyer': parseInt(montant_loyer) })
                Object.assign(object['inputs'], { 'duree_bail': parseInt(duree) })
                Object.assign(object['inputs'], { 'valeur_residuelle': parseInt(value) })
                Object.assign(object['inputs'], { 'nombre_page_copie': parseInt(page) })
                object.departementid = parseInt(departement)
            }

            if (setter === setPage) {
                Object.assign(object['inputs'], { 'montant_loyer': parseInt(montant_loyer) })
                Object.assign(object['inputs'], { 'duree_bail': parseInt(duree) })
                Object.assign(object['inputs'], { 'valeur_residuelle': parseInt(valeurResiduelle) })
                Object.assign(object['inputs'], { 'nombre_page_copie': parseInt(value) })
                object.departementid = parseInt(departement)
            }

            dispatch(setSimulateurObject(object));

        } else {

            // process child simu

        }
    }

    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

                <TextfieldRemarques handleChange={handleChangeRemarque} />

                <p style={styles.cardSubTitle}>
                    (Acte tarifé au titre du Tableau 5, n° 77). La provision sur frais calculée comprend la taxe de publicité foncière à 0,715 %.
                    <br />Pour rappel : Le bail emphytéotique est conclu pour une durée minimale de 18 ans.
                </p>

                {props?.isParent === true && props.departements !== "" ?
                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            Département :
                        </div>

                        <SelectDepartement items={props.departements} nameField={"name"} defaultValue={currUserDep} optionValue={"id"}
                            handleChange={handleChange} setter={setDepartement} />
                    </div>
                    :
                    <></>
                }

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Quel est le montant cumulé des loyers dus pour la durée du bail (en ce compris les charges éventuelles) ?
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer le montant"}
                        setter={setMontant}
                    />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Quelle est la durée du bail (en année) ?
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer la durée"}
                        setter={setDuree}
                    />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Quelle est la valeur résiduelle des constructions ?
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer la valeur"}
                        setter={setValeurResiduelle}
                    />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Quel est le nombre de page(s) de la copie exécutoire ?
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Nombre de pages"}
                        setter={setPage}
                    />
                </div>
            </div>
        </div>
    )
}

export default Simulateur_A1_28;
