import React, { useEffect, useState } from 'react';
import TextfieldNumber from "../textfields/textfield.number";
import { styles } from "../../stylesheets/simulateurs.css";
import SelectCommon from "../selects/select.common";
import ButtonYesno from "../buttons/button.yesno.js";
import { useDispatch } from 'react-redux';
import { setDMTGObject } from "../../actions/dmtg.action";
import { ToolTipPerso } from '../selects/tooltip_perso';
import TextfieldAbattement from "../textfields/textfield.abattement";


const Successions_Donataire = (props) => {
    const dispatch = useDispatch();

    // Ici props contient donc :
    // props.resultKey => key du tableau "results" nécessaire pour setter la bonne valeur
    // props.functionSetResults => pour lancer la fonction du main depuis le component
    // ----

    // States avec les différentes valeurs à stocker
    // Possibilité également de mettre tous les states (value1, value2....) dans un seul state sous format:
    //
    // state: {
    //      value1: {},
    //      value2: {},
    //      ...
    // }
    //
    // Dans ce cas penser à changer le callback du useEffect qui prend plus bas [value1, value2]
    // ---

    const [lien_parente_succession, setLien] = useState(0)
    const [conditions_particulieres, setConditions] = useState(0)
    const [propre_chef, setPropreChef] = useState(0)
    const [handicap, setHandicap] = useState(0)
    const [montant_succession, setMontantSuccesion] = useState(0)
    const [abattement_legal, setAbattementLegal] = useState(0)
    const [montant_utilise, setMontantUtilise] = useState(0)

    const handleChange = async (setter, value) => {
        setter(value)
        setInRedux(setter, value)
    };

    function setInRedux(setter, value) {
        let object = { "simulateurid": props.simulateurid, "inputs": {} };
        if (setter === setLien) {
            Object.assign(object['inputs'], { 'lien_parente_succession': parseInt(value) })
            Object.assign(object['inputs'], { 'conditions_particulieres': parseInt(conditions_particulieres) })
            Object.assign(object['inputs'], { 'propre_chef': parseInt(propre_chef) })
            Object.assign(object['inputs'], { 'handicap': parseInt(handicap) })
            Object.assign(object['inputs'], { 'montant_succession': parseInt(montant_succession) })
            Object.assign(object['inputs'], { 'montant_utilise': parseInt(montant_utilise) })
            Object.assign(object['inputs'], { 'donateur': parseInt(props.donateur) })
            Object.assign(object['inputs'], { 'donataire': parseInt(props.soloResultKey + 1) })
        }

        if (setter === setConditions) {
            Object.assign(object['inputs'], { 'lien_parente_succession': parseInt(lien_parente_succession) })
            Object.assign(object['inputs'], { 'conditions_particulieres': parseInt(value) })
            Object.assign(object['inputs'], { 'propre_chef': parseInt(propre_chef) })
            Object.assign(object['inputs'], { 'handicap': parseInt(handicap) })
            Object.assign(object['inputs'], { 'montant_succession': parseInt(montant_succession) })
            Object.assign(object['inputs'], { 'montant_utilise': parseInt(montant_utilise) })
            Object.assign(object['inputs'], { 'donateur': parseInt(props.donateur) })
            Object.assign(object['inputs'], { 'donataire': parseInt(props.soloResultKey + 1) })
        }

        if (setter === setPropreChef) {
            Object.assign(object['inputs'], { 'lien_parente_succession': parseInt(lien_parente_succession) })
            Object.assign(object['inputs'], { 'conditions_particulieres': parseInt(conditions_particulieres) })
            Object.assign(object['inputs'], { 'propre_chef': parseInt(value) })
            Object.assign(object['inputs'], { 'handicap': parseInt(handicap) })
            Object.assign(object['inputs'], { 'montant_succession': parseInt(montant_succession) })
            Object.assign(object['inputs'], { 'montant_utilise': parseInt(montant_utilise) })
            Object.assign(object['inputs'], { 'donateur': parseInt(props.donateur) })
            Object.assign(object['inputs'], { 'donataire': parseInt(props.soloResultKey + 1) })
        }

        if (setter === setHandicap) {
            if (value === 1 && lien_parente_succession === "1") {
                handleChange(setLien, "1")
            }
            Object.assign(object['inputs'], { 'lien_parente_succession': parseInt(lien_parente_succession) })
            Object.assign(object['inputs'], { 'conditions_particulieres': parseInt(conditions_particulieres) })
            Object.assign(object['inputs'], { 'propre_chef': parseInt(propre_chef) })
            Object.assign(object['inputs'], { 'handicap': parseInt(value) })
            Object.assign(object['inputs'], { 'montant_succession': parseInt(montant_succession) })
            Object.assign(object['inputs'], { 'montant_utilise': parseInt(montant_utilise) })
            Object.assign(object['inputs'], { 'donateur': parseInt(props.donateur) })
            Object.assign(object['inputs'], { 'donataire': parseInt(props.soloResultKey + 1) })
        }

        if (setter === setMontantSuccesion) {
            Object.assign(object['inputs'], { 'lien_parente_succession': parseInt(lien_parente_succession) })
            Object.assign(object['inputs'], { 'conditions_particulieres': parseInt(conditions_particulieres) })
            Object.assign(object['inputs'], { 'propre_chef': parseInt(propre_chef) })
            Object.assign(object['inputs'], { 'handicap': parseInt(handicap) })
            Object.assign(object['inputs'], { 'montant_succession': parseInt(value) })
            Object.assign(object['inputs'], { 'montant_utilise': parseInt(montant_utilise) })
            Object.assign(object['inputs'], { 'donateur': parseInt(props.donateur) })
            Object.assign(object['inputs'], { 'donataire': parseInt(props.soloResultKey + 1) })
        }

        if (setter === setMontantUtilise) {
            Object.assign(object['inputs'], { 'lien_parente_succession': parseInt(lien_parente_succession) })
            Object.assign(object['inputs'], { 'conditions_particulieres': parseInt(conditions_particulieres) })
            Object.assign(object['inputs'], { 'propre_chef': parseInt(propre_chef) })
            Object.assign(object['inputs'], { 'handicap': parseInt(handicap) })
            Object.assign(object['inputs'], { 'montant_succession': parseInt(montant_succession) })
            Object.assign(object['inputs'], { 'montant_utilise': parseInt(value) })
            Object.assign(object['inputs'], { 'donateur': parseInt(props.donateur) })
            Object.assign(object['inputs'], { 'donataire': parseInt(props.soloResultKey + 1) })
        }


        /*if (setter === setNomHeritier) {
            props.functionSetDonatairesList(props.soloResultKey, { "nom": value })
        }*/

        props.functionSetSoloResults(props.soloResultKey, object)
        dispatch(setDMTGObject(object));
    }

    const lienDonation = [
        {
            id: 1,
            name: "En ligne directe - Ascendants ou enfants"
        },
        {
            id: 2,
            name: "Entre frères et sœurs"
        },
        {
            id: 3,
            name: "Aux neveux et nièces"
        },
        {
            id: 4,
            name: "Entre collatéraux jusqu'au 4ème degré inclusivement"
        },
        {
            id: 5,
            name: "Entre parents au-delà du 4ème degré et non parents"
        }]

    // Hook appelé à la génération de la page car aucune restriction n'est imposé aux fields value1 et value2.
    // Ce hook captera chaque modification de field et appelera la fonction du App.js "FunctionSetResults"
    // pour set la key correspondante à l'appel de component dans le state "results".

    useEffect(() => {
        if (lien_parente_succession === "1" && handicap === 1) {
            setAbattementLegal("259 325");
        } else if (lien_parente_succession === "1") {
            handleChange(setAbattementLegal, "100 000");
        } else if (lien_parente_succession === "2" && handicap === 1) {
            setAbattementLegal("175 257");
        } else if (lien_parente_succession === "2") {
            handleChange(setAbattementLegal, "15 932");
        } else if (lien_parente_succession === "3" && handicap === 1) {
            setAbattementLegal("167 286");
        } else if (lien_parente_succession === "3") {
            handleChange(setAbattementLegal, "7 967");
        } else if (((lien_parente_succession === "4") || (lien_parente_succession === "5")) && handicap === 1) {
            handleChange(setAbattementLegal, "160 919");
        } else if ((lien_parente_succession === "4") || (lien_parente_succession === "5")) {
            handleChange(setAbattementLegal, "1 594");
        }
    }, [lien_parente_succession, handicap]);

    return (
        <div style={styles.cardFlexContainer}>
            {/*            <div>
                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Indiquer le nom de l'héritier
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"text"}
                        placeholder={"Indiquer le nom"}
                        setter={setNomHeritier}
                    />
                </div>
            </div>*/}

            <div style={styles.cardTitleDonataire}>
                Héritier {props.soloResultKey + 1}
            </div>

            <div style={styles.cardRow}>
                <div style={styles.cardElemVerticalyCenter}>
                    Quel est leur lien de parenté ?
                </div>
                <SelectCommon items={lienDonation} defaultValue={"1"} nameField={"name"} optionValue={"id"}
                    handleChange={handleChange} setter={setLien} />
            </div>

            {
                parseInt(lien_parente_succession) === 2 ?
                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            Conditions particulières ?
                            <ToolTipPerso title={"Être célibataire, veuf, divorcé ou séparé de corps et à la double condition : d’être âgé de plus de cinquante ans ou infirme et avoir été domicilié constamment chez le défunt pendant les 5 années qui ont précédé le décès."} />
                        </div>
                        <ButtonYesno handleChange={handleChange} setter={setConditions} defaultValue={0} />
                    </div>
                    :
                    <></>
            }

            {
                parseInt(conditions_particulieres) === 1 ?
                    <div>
                        <div style={styles.messageInABottle}>
                            Le ou les bénéficaires sont exonérés de droits
                        </div>
                    </div>
                    :
                    <></>
            }

            {
                parseInt(lien_parente_succession) === 3 ?
                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            Héritent-ils de leur propre chef ?
                            <ToolTipPerso title={"Sont taxés au tarif applicable entre frères et sœurs soit 35% et 45% lorsqu’ils viennent en représentation de leur parents. Lorsqu’ils héritent de leur propre chef le taux de 55% est applicable. Cette mesure est rétroactive au 1er janvier 2007."} />
                        </div>
                        <ButtonYesno handleChange={handleChange} setter={setPropreChef} defaultValue={0} />
                    </div>
                    :
                    <></>
            }

            <div style={styles.cardRow}>
                <div style={styles.cardElemVerticalyCenter}>
                    Le bénéficiaire est-il en situation de handicap ?
                    <ToolTipPerso title={"En cas d’invalidité permanente, abattement de 159 325€ cumulable."} />
                </div>
                <ButtonYesno handleChange={handleChange} setter={setHandicap} defaultValue={0} />
            </div>

            <div style={styles.cardRow}>
                <div style={styles.cardElemVerticalyCenter}>
                    Montant de la succession lui revenant
                </div>
                <TextfieldNumber
                    handleChange={handleChange}
                    type={"number"}
                    placeholder={"Indiquer le montant"}
                    setter={setMontantSuccesion}
                />
            </div>

            <div style={styles.cardRow}>
                <div style={styles.cardElemVerticalyCenter}>
                    Abattement légal
                </div>
                <TextfieldAbattement
                    type={"number"}
                    placeholder={abattement_legal + " €"}
                />
            </div>

            <div style={styles.cardRow}>
                <div style={styles.cardElemVerticalyCenter}>
                    Part de l’abattement légal déjà utilisé
                </div>
                <TextfieldNumber
                    handleChange={handleChange}
                    type={"number"}
                    placeholder={"Indiquer le montant"}
                    setter={setMontantUtilise}
                />
            </div>

        </div>
    );
}
    ;

export default Successions_Donataire;
