import React, { useEffect, useState } from "react";
import { setSimulateurObject } from "../../../actions/simulateur.action";
import { useDispatch } from 'react-redux';
import { styles } from "../../../stylesheets/simulateurs.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import SelectDepartement from "../../../components/selects/select.departement";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";


// Partage pur et simple de société autre que société de construction
// Id simulateur 79

function Simulateur_79(props, simulateur, isParent, departements) {
    const dispatch = useDispatch();

    let currUser = JSON.parse(localStorage.getItem("user"));
    let currUserDep = 0
    if (currUser.societe_zip.startsWith("97")) {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 3);
        currUserDep = parseInt(trimedZip)
    } else {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 2);
        currUserDep = parseInt(trimedZip)
    }

    const [departement, setDepartement] = useState(0)
    const [montant_partage_brut, setPartageBrut] = useState(0)
    const [montant_partage_net, setPartageNet] = useState(0)
    const [valeur_biens_partages, setBiens] = useState(0)
    const [nombre_spf, setSPF] = useState(0)

    const handleChange = async (setter, value) => {
        if (setter === setDepartement) {
            setDepartement(parseInt(value))
        } else setter(value);
        setInRedux(setter, value)
    };

    const handleChangeRemarque = async (value) => {
        localStorage.setItem("remarque", value)
    }

    function setInRedux(setter, value) {
        if (props.isParent) {

            let object = {
                "simulateurid": props.simulateur.id,
                "departementid": 0,
                "inputs": {}
            };

            if (setter === setDepartement) {
                Object.assign(object['inputs'], { 'montant_partage_brut': parseInt(montant_partage_brut) })
                Object.assign(object['inputs'], { 'montant_partage_net': parseInt(montant_partage_net) })
                Object.assign(object['inputs'], { 'valeur_biens_partages': parseInt(valeur_biens_partages) })
                Object.assign(object['inputs'], { 'nombre_spf': parseInt(nombre_spf) })
                object.departementid = parseInt(value)
            }

            if (setter === setPartageBrut) {
                Object.assign(object['inputs'], { 'montant_partage_brut': parseInt(value) })
                Object.assign(object['inputs'], { 'montant_partage_net': parseInt(montant_partage_net) })
                Object.assign(object['inputs'], { 'valeur_biens_partages': parseInt(valeur_biens_partages) })
                Object.assign(object['inputs'], { 'nombre_spf': parseInt(nombre_spf) })
                object.departementid = parseInt(departement)
            }

            if (setter === setPartageNet) {
                Object.assign(object['inputs'], { 'montant_partage_brut': parseInt(montant_partage_brut) })
                Object.assign(object['inputs'], { 'montant_partage_net': parseInt(value) })
                Object.assign(object['inputs'], { 'valeur_biens_partages': parseInt(valeur_biens_partages) })
                Object.assign(object['inputs'], { 'nombre_spf': parseInt(nombre_spf) })
                object.departementid = parseInt(departement)
            }

            if (setter === setBiens) {
                Object.assign(object['inputs'], { 'montant_partage_brut': parseInt(montant_partage_brut) })
                Object.assign(object['inputs'], { 'montant_partage_net': parseInt(montant_partage_net) })
                Object.assign(object['inputs'], { 'valeur_biens_partages': parseInt(value) })
                Object.assign(object['inputs'], { 'nombre_spf': parseInt(nombre_spf) })
                object.departementid = parseInt(departement)
            }

            if (setter === setSPF) {
                Object.assign(object['inputs'], { 'montant_partage_brut': parseInt(montant_partage_brut) })
                Object.assign(object['inputs'], { 'montant_partage_net': parseInt(montant_partage_net) })
                Object.assign(object['inputs'], { 'valeur_biens_partages': parseInt(valeur_biens_partages) })
                Object.assign(object['inputs'], { 'nombre_spf': parseInt(value) })
                object.departementid = parseInt(departement)
            }

            dispatch(setSimulateurObject(object));

        } else {

            // process child simu

        }
    }

    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

                <TextfieldRemarques handleChange={handleChangeRemarque} />

                <p style={styles.cardSubTitle}>
                    Acte tarifé au titre du Tableau 5, n° 101.
                </p>

                {props?.isParent === true && props.departements !== "" ?
                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            Département :
                        </div>

                        <SelectDepartement items={props.departements} nameField={"name"} defaultValue={currUserDep} optionValue={"id"}
                            handleChange={handleChange} setter={setDepartement} />
                    </div>
                    :
                    <></>
                }

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Quel est le montant de l’actif brut à partager ?
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer le montant"}
                        setter={setPartageBrut}
                    />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Quel est le montant de l’actif net partagé ?
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer le montant"}
                        setter={setPartageNet}
                    />
                </div>


                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Quelle est la valeur totale des biens immobiliers partagés ?
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer la valeur"}
                        setter={setBiens}
                    />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Quel est le nombre de SPF concernés par le partage ?
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer le nombre"}
                        setter={setSPF}
                    />
                </div>


            </div>

        </div>
    )
}

export default Simulateur_79;
