import React, { useEffect, useState } from "react";
import { styles } from "../../../stylesheets/simulateurs.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import Button12 from "../../../components/buttons/button.12";
import Donations from "../../../components/dmtg/donations";
import { setDMTGResultsObject } from "../../../actions/dmtg_results.action";
import { useDispatch } from "react-redux";
import Successions from "../../../components/dmtg/successions";
import { ToolTipPerso } from "../../../components/selects/tooltip_perso";
import ButtonYesno from "../../../components/buttons/button.yesno";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";

function Simulateur_Successions(props, isParent) {
    const dispatch = useDispatch()
    let currUser = JSON.parse(localStorage.getItem("user"));
    let currUserDep = 0
    if (currUser.societe_zip.startsWith("97")) {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 3);
        currUserDep = parseInt(trimedZip)
    } else {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 2);
        currUserDep = parseInt(trimedZip)
    }

    const [donateurs, setDonateurs] = useState(0)
    const [cas_particulier, setParticulier] = useState(0)
    const [donataires, setDonataires] = useState(1)
    const [allDonatairesList, setAllDonatairesList] = useState([{}])
    const [results, setResults] = useState([{}])

    // Function permettant d'avoir la main sur la modification du nombre de components demandé et d'y ajouter une limite
    // pour éviter la génération abusive.
    const handleChange = async (setter, value) => {
        setter(value)
    };
    const handleChangeWithRestriction = async (setter, value) => {
        // Limite à adapter --v
        if (value < 10)
            setter(value)
        else setter(9)
    };

    const handleChangeRemarque = async (value) => {
        localStorage.setItem("remarque", value)
    }

    // Fonction call depuis le component enfant qui set la key du state "results" modifiée.
    function FunctionSetResults(key, value) {
        results[key] = value
        setInRedux(results)
    }

    function FunctionSetAllDonatairesList(key, value) {
        allDonatairesList[key] = value
        //console.log("iciii")
        //console.log(value)
    }

    function setInRedux(results) {
        let object = { "results": results };
        Object.assign(object['results'], results)
        //console.log(object)
        dispatch(setDMTGResultsObject(object));
    }

    //props?.isParent === true
    return (
        <div style={styles.container}>
            <div style={props.callSinceSimu ? styles.cardDMTG : styles.card}>
                {props.callSinceSimu ?
                    <></>
                    :
                    <>
                        <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

                        <TextfieldRemarques handleChange={handleChangeRemarque}/>
                    </>}

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Combien d'héritiers sont concernés ?
                    </div>
                    <TextfieldNumber
                        handleChange={handleChangeWithRestriction}
                        type={"number"}
                        defaultValue={1}
                        placeholder={"Nombre d'héritiers"}
                        setter={setDonataires}
                    />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Le défunt était-il dans un cas particulier ?
                        <ToolTipPerso
                            title={"Victime de guerre ou d'acte de terrorisme ; Militaire décédé en opération extérieure ou au cours d'une opération de sécurité intérieure ou décédé suite à des blessures reçues durant de telles opérations ; Sapeur-pompier, policier, gendarme ou agent des douanes, cité à l'ordre de la Nation, décédé en service ou suite à des blessures reçues en service."} />
                    </div>
                    <ButtonYesno handleChange={handleChange} setter={setParticulier} defaultValue={0} />
                </div>

                {
                    parseInt(cas_particulier) === 1 ?
                        <div>
                            <div style={styles.messageInABottle}>
                                Le ou les bénéficaires sont exonérés de droits
                            </div>
                        </div>
                        :
                        <></>
                }

                <div style={styles.cardDonateurs}>
                    <Successions
                        handleChange={handleChange}
                        resultKey={0}
                        functionSetResults={FunctionSetResults}
                        FunctionSetAllDonatairesList={FunctionSetAllDonatairesList}
                        donataires={donataires}
                        simulateurid={props.simulateur.id}
                    />
                </div>
            </div>
        </div>
    )
}

export default Simulateur_Successions;
