import React, { useEffect, useState } from "react";
import { setSimulateurObject } from "../../../actions/simulateur.action";
import { useDispatch } from 'react-redux';
import { styles } from "../../../stylesheets/simulateurs.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import SelectDepartement from "../../../components/selects/select.departement";
import ButtonYesno from "../../../components/buttons/button.yesno";
import ButtonTVA from "../../../components/buttons/button.txTVA";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";

// Contrat de crédit-bail
// Id simulateur 37


function Simulateur_37(props, simulateur, isParent, departements) {
    const dispatch = useDispatch();

    const currentIdSimu = 37
    let currUser = JSON.parse(localStorage.getItem("user"));
    let currUserDep = 0
    if (currUser.societe_zip.startsWith("97")) {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 3);
        currUserDep = parseInt(trimedZip)
    } else {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 2);
        currUserDep = parseInt(trimedZip)
    }

    const [departement, setDepartement] = useState(0)
    const [montant_investissement, setMontantInvestissement] = useState(0)
    const [duree_bail, setDuree] = useState(0)
    const [nombre_copie_executoire, setCopieExecutoire] = useState(0)
    const [montant_loyer_mensuel_ht, setMontantLoyer] = useState(0)
    const [loyer_tva, setLoyerTva] = useState(0)
    const [loyer_taux_tva, setLoyerTvaTaux] = useState(0)
    const [montant_frais_financier, setMontantFinancier] = useState(0)

    const handleChange = async (setter, value) => {
        if (setter === setDepartement) {
            setDepartement(parseInt(value))
        } else setter(value);
        setInRedux(setter, value)
    };

    const handleChangeRemarque = async (value) => {
        localStorage.setItem("remarque", value)
    }

    function setInRedux(setter, value) {
        if (props.isParent) {

            let object = {
                "simulateurid": props.simulateur.id,
                "departementid": 0,
                "inputs": {}
            };

            if (setter === setDepartement) {
                Object.assign(object['inputs'], { 'montant_investissement': parseInt(montant_investissement) })
                Object.assign(object['inputs'], { 'duree_bail': parseInt(duree_bail) })
                Object.assign(object['inputs'], { 'nombre_copie_executoire': parseInt(nombre_copie_executoire) })
                Object.assign(object['inputs'], { 'montant_loyer_mensuel_ht': parseInt(montant_loyer_mensuel_ht) })
                Object.assign(object['inputs'], { 'loyer_tva': parseInt(loyer_tva) })
                Object.assign(object['inputs'], { 'loyer_taux_tva': parseFloat(loyer_taux_tva) })
                Object.assign(object['inputs'], { 'montant_frais_financier': parseInt(montant_frais_financier) })
                object.departementid = parseInt(value)
            }

            if (setter === setMontantInvestissement) {
                Object.assign(object['inputs'], { 'montant_investissement': parseInt(value) })
                Object.assign(object['inputs'], { 'duree_bail': parseInt(duree_bail) })
                Object.assign(object['inputs'], { 'nombre_copie_executoire': parseInt(nombre_copie_executoire) })
                Object.assign(object['inputs'], { 'montant_loyer_mensuel_ht': parseInt(montant_loyer_mensuel_ht) })
                Object.assign(object['inputs'], { 'loyer_tva': parseInt(loyer_tva) })
                Object.assign(object['inputs'], { 'loyer_taux_tva': parseFloat(loyer_taux_tva) })
                Object.assign(object['inputs'], { 'montant_frais_financier': parseInt(montant_frais_financier) })
                object.departementid = parseInt(departement)
            }

            if (setter === setDuree) {
                Object.assign(object['inputs'], { 'montant_investissement': parseInt(montant_investissement) })
                Object.assign(object['inputs'], { 'duree_bail': parseInt(value) })
                Object.assign(object['inputs'], { 'nombre_copie_executoire': parseInt(nombre_copie_executoire) })
                Object.assign(object['inputs'], { 'montant_loyer_mensuel_ht': parseInt(montant_loyer_mensuel_ht) })
                Object.assign(object['inputs'], { 'loyer_tva': parseInt(loyer_tva) })
                Object.assign(object['inputs'], { 'loyer_taux_tva': parseFloat(loyer_taux_tva) })
                Object.assign(object['inputs'], { 'montant_frais_financier': parseInt(montant_frais_financier) })
                object.departementid = parseInt(departement)
            }

            if (setter === setCopieExecutoire) {
                Object.assign(object['inputs'], { 'montant_investissement': parseInt(montant_investissement) })
                Object.assign(object['inputs'], { 'duree_bail': parseInt(duree_bail) })
                Object.assign(object['inputs'], { 'nombre_copie_executoire': parseInt(value) })
                Object.assign(object['inputs'], { 'montant_loyer_mensuel_ht': parseInt(montant_loyer_mensuel_ht) })
                Object.assign(object['inputs'], { 'loyer_tva': parseInt(loyer_tva) })
                Object.assign(object['inputs'], { 'loyer_taux_tva': parseFloat(loyer_taux_tva) })
                Object.assign(object['inputs'], { 'montant_frais_financier': parseInt(montant_frais_financier) })
                object.departementid = parseInt(departement)
            }

            if (setter === setMontantLoyer) {
                Object.assign(object['inputs'], { 'montant_investissement': parseInt(montant_investissement) })
                Object.assign(object['inputs'], { 'duree_bail': parseInt(duree_bail) })
                Object.assign(object['inputs'], { 'nombre_copie_executoire': parseInt(nombre_copie_executoire) })
                Object.assign(object['inputs'], { 'montant_loyer_mensuel_ht': parseInt(value) })
                Object.assign(object['inputs'], { 'loyer_tva': parseInt(loyer_tva) })
                Object.assign(object['inputs'], { 'loyer_taux_tva': parseFloat(loyer_taux_tva) })
                Object.assign(object['inputs'], { 'montant_frais_financier': parseInt(montant_frais_financier) })
                object.departementid = parseInt(departement)
            }

            if (setter === setLoyerTva) {
                Object.assign(object['inputs'], { 'montant_investissement': parseInt(montant_investissement) })
                Object.assign(object['inputs'], { 'duree_bail': parseInt(duree_bail) })
                Object.assign(object['inputs'], { 'nombre_copie_executoire': parseInt(nombre_copie_executoire) })
                Object.assign(object['inputs'], { 'montant_loyer_mensuel_ht': parseInt(montant_loyer_mensuel_ht) })
                Object.assign(object['inputs'], { 'loyer_tva': parseInt(value) })
                Object.assign(object['inputs'], { 'loyer_taux_tva': parseFloat(loyer_taux_tva) })
                Object.assign(object['inputs'], { 'montant_frais_financier': parseInt(montant_frais_financier) })
                object.departementid = parseInt(departement)
            }

            if (setter === setLoyerTvaTaux) {
                Object.assign(object['inputs'], { 'montant_investissement': parseInt(montant_investissement) })
                Object.assign(object['inputs'], { 'duree_bail': parseInt(duree_bail) })
                Object.assign(object['inputs'], { 'nombre_copie_executoire': parseInt(nombre_copie_executoire) })
                Object.assign(object['inputs'], { 'montant_loyer_mensuel_ht': parseInt(montant_loyer_mensuel_ht) })
                Object.assign(object['inputs'], { 'loyer_tva': parseInt(loyer_tva) })
                Object.assign(object['inputs'], { 'loyer_taux_tva': parseFloat(value) })
                Object.assign(object['inputs'], { 'montant_frais_financier': parseInt(montant_frais_financier) })
                object.departementid = parseInt(departement)
            }

            if (setter === setMontantFinancier) {
                Object.assign(object['inputs'], { 'montant_investissement': parseInt(montant_investissement) })
                Object.assign(object['inputs'], { 'duree_bail': parseInt(duree_bail) })
                Object.assign(object['inputs'], { 'nombre_copie_executoire': parseInt(nombre_copie_executoire) })
                Object.assign(object['inputs'], { 'montant_loyer_mensuel_ht': parseInt(montant_loyer_mensuel_ht) })
                Object.assign(object['inputs'], { 'loyer_tva': parseInt(loyer_tva) })
                Object.assign(object['inputs'], { 'loyer_taux_tva': parseFloat(loyer_taux_tva) })
                Object.assign(object['inputs'], { 'montant_frais_financier': parseInt(value) })
                object.departementid = parseInt(departement)
            }

            dispatch(setSimulateurObject(object));

        } else {

            // process child simu

        }
    }

    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

                <TextfieldRemarques handleChange={handleChangeRemarque} />

                <p style={styles.cardSubTitle}>
                    Acte tarifé au titre du Tableau 5, n° 114.
                </p>

                {props?.isParent === true && props.departements !== "" ?
                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            Département :
                        </div>

                        <SelectDepartement items={props.departements} nameField={"name"} defaultValue={currUserDep} optionValue={"id"}
                            handleChange={handleChange} setter={setDepartement} />
                    </div>
                    :
                    <></>
                }

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Quel est le montant total de l’investissement ?
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer le montant"}
                        setter={setMontantInvestissement}
                    />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Quelle est la durée (en année) du contrat de crédit-bail ?
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer la durée"}
                        setter={setDuree}
                    />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Quel est le nombre de page(s) de la copie exécutoire ?
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer le nombre"}
                        setter={setCopieExecutoire}
                    />
                </div>

                {
                    parseInt(duree_bail) >= 12 ?
                        <div style={styles.cardRow}>
                            <div style={styles.cardElemVerticalyCenter}>
                                Quel est le montant HT mensuel des loyers ?
                            </div>
                            <TextfieldNumber
                                handleChange={handleChange}
                                type={"number"}
                                placeholder={"Indiquer le montant"}
                                setter={setMontantLoyer}
                            />
                        </div>
                        :
                        <></>
                }

                {
                    parseInt(duree_bail) >= 12 ?
                        <div style={styles.cardRow}>
                            <div style={styles.cardElemVerticalyCenter}>
                                Les loyers sont-ils soumis à TVA ?
                            </div>
                            <div>
                                <ButtonYesno handleChange={handleChange} setter={setLoyerTva} fieldUndefined={false}
                                    defaultValue={0} />
                            </div>
                        </div>
                        :
                        <></>
                }

                {
                    parseInt(loyer_tva) === 1 ?
                        <div style={styles.cardRow}>
                            <div style={styles.cardElemVerticalyCenter}>
                                A quel taux de TVA ?
                            </div>
                            <ButtonTVA handleChange={handleChange} setter={setLoyerTvaTaux} fieldUndefined={false}
                                defaultValue={1} />
                        </div>
                        :
                        <></>
                }

                {
                    parseInt(duree_bail) >= 12 ?
                        <div style={styles.cardRow}>
                            <div style={styles.cardElemVerticalyCenter}>
                                Quel est le montant des frais financiers indiqués dans l’acte ?
                            </div>
                            <TextfieldNumber
                                handleChange={handleChange}
                                type={"number"}
                                placeholder={"Indiquer le montant"}
                                setter={setMontantFinancier}
                            />
                        </div>
                        :
                        <></>
                }

            </div>

        </div>
    )
}

export default Simulateur_37;
