import HelpIcon from "@mui/icons-material/Help";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import React from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';


const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    width:"auto",
    fontSize:14,
    textAlign:"justify",
    backgroundColor:"#275d83",
    padding:10,
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    // border:"1px lightgrey solid",
  },
});

export const ToolTipPerso = (props) => {

    return (<CustomWidthTooltip  title={props.title} placement="right">
        <HelpIcon style={{fill: "#275d83", marginLeft: 4, marginBottom: -3, width: 18, height: 18}}/>
    </CustomWidthTooltip>)
}