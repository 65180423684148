import React, { useEffect, useState } from "react";
import Colors from "../../useclass/colors";
import layout1 from "../../assets/images/notaire.jpg";
import layout2 from "../../assets/images/immobilier.jpg";
import layout3 from "../../assets/images/family.jpg";
import Footer from "../../layouts/footer/footer";
import InfosFooter from "../../layouts/infosfooter/infosfooter";

function Fiches(props) {
  const styles = {
    block1: {
      position: "relative",
      width: 350,
      height: 350,
      backgroundImage: `url(${layout1})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      borderRadius: 8,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      boxShadow: "2px 2px 1px rgba(0,0,0,0.2)",
      margin: 20,
      color: "#ffffff",
      cursor: "pointer",
    },
    blockOverlay: {
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(94, 145, 182, 0.6)",
      borderRadius: 8,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 30,
      fontWeight: 900,
      textAlign: "center",
    },
    block2: {
      width: 350,
      height: 350,
      backgroundImage: `url(${layout2})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      borderRadius: 8,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      boxShadow: "2px 2px 1px rgba(0,0,0, 0.2)",
      margin: 20,
      color: "#ffff",
      cursor: "pointer",
    },
    block3: {
      width: 350,
      height: 350,
      backgroundImage: `url(${layout3})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      borderRadius: 8,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      boxShadow: "2px 2px 1px rgba(0,0,0, 0.2)",
      margin: 20,
      color: "#ffff",
      cursor: "pointer",
    },
    blocksContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      alignContent: "space-around",
      margin: 20,
      flexWrap: "wrap",
    },
    card: {
      backgroundColor: Colors.white,
      boxShadow: "2px 2px 1px rgba(0,0,0, 0.2)",
      borderRadius: 5,
      width: "90%",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: 300,
      paddingBottom: 50,
      minHeight: "30vh",
    },
    cardTitle: {
      fontWeight: "normal",
      color: "#275d83",
      textAlign: "center",
      lineHeight: "1.4em",
      paddingTop: 20,
      marginTop: 5,
      marginBottom: 30,
    },
    cardSubTitle: {
      fontSize: 18,
      marginLeft: 35,
    },
    container: {
      padding: 0,
      margin: 0,
      width: "100vw",
      minHeight: "auto",
    },
    largeScreenFooter: {
      left: 0,
      bottom: 0,
    },
    footer: {
      position: "relative",
    },
  };


  return (
    <>
      <div style={styles.container}>
        <div style={styles.card}>
          <h1 style={styles.cardTitle}>Fiches Pratiques</h1>

          <div style={styles.blocksContainer}>
            <div style={styles.block1}>
              <div
                style={styles.blockOverlay}
                onClick={() => (window.location = `fiches/fiches_generales`)}
              >
                INFORMATIONS GÉNÉRALES
              </div>
            </div>
            <div style={styles.block2}>
              {" "}
              <div
                style={styles.blockOverlay}
                onClick={() => (window.location = `fiches/fiches_immo`)}
              >
                INFORMATIONS SUR L'IMMOBILIER
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={window.innerWidth > 768 ? styles.largeScreenFooter : styles.footer}>
        <InfosFooter />
        <Footer />
      </div>
    </>
  );
}

export default Fiches;
