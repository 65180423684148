import React, { useEffect, useState } from "react";
import { setSimulateurObject } from "../../../actions/simulateur.action";
import { useDispatch } from 'react-redux';
import { styles } from "../../../stylesheets/simulateurs.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import SelectDepartement from "../../../components/selects/select.departement";
import ButtonYesno from "../../../components/buttons/button.yesno";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";

// Crédit-bail : Achat par une société de crédit-bail à un tiers
// Id simulateur 41

function Simulateur_41(props, simulateur, isParent, departements) {
    const dispatch = useDispatch();

    const currentIdSimu = 41
    let currUser = JSON.parse(localStorage.getItem("user"));
    let currUserDep = 0
    if (currUser.societe_zip.startsWith("97")) {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 3);
        currUserDep = parseInt(trimedZip)
    } else {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 2);
        currUserDep = parseInt(trimedZip)
    }

    const [departement, setDepartement] = useState(0)
    const [montant_vente, setMontantVente] = useState(0)
    const [terrain_a_batir, setTerrain] = useState(0)
    const [engagement_construire, setEngagementConstruire] = useState(0)

    const handleChange = async (setter, value) => {
        if (setter === setDepartement) {
            setDepartement(parseInt(value))
        } else setter(value);
        setInRedux(setter, value)
    };

    const handleChangeRemarque = async (value) => {
        localStorage.setItem("remarque", value)
    }

    function setInRedux(setter, value) {
        if (props.isParent) {

            let object = {
                "simulateurid": props.simulateur.id,
                "departementid": 0,
                "inputs": {}
            };

            if (setter === setDepartement) {
                Object.assign(object['inputs'], { 'montant_vente': parseInt(montant_vente) })
                Object.assign(object['inputs'], { 'terrain_a_batir': parseInt(terrain_a_batir) })
                Object.assign(object['inputs'], { 'engagement_contruire': parseInt(engagement_construire) })
                object.departementid = parseInt(value)
            }

            if (setter === setMontantVente) {
                Object.assign(object['inputs'], { 'montant_vente': parseInt(value) })
                Object.assign(object['inputs'], { 'terrain_a_batir': parseInt(terrain_a_batir) })
                Object.assign(object['inputs'], { 'engagement_construire': parseInt(engagement_construire) })
                object.departementid = parseInt(departement)
            }

            if (setter === setTerrain) {
                Object.assign(object['inputs'], { 'montant_vente': parseInt(montant_vente) })
                Object.assign(object['inputs'], { 'terrain_a_batir': parseInt(value) })
                Object.assign(object['inputs'], { 'engagement_construire': parseInt(engagement_construire) })
                object.departementid = parseInt(departement)
            }

            if (setter === setEngagementConstruire) {
                Object.assign(object['inputs'], { 'montant_vente': parseInt(montant_vente) })
                Object.assign(object['inputs'], { 'terrain_a_batir': parseInt(terrain_a_batir) })
                Object.assign(object['inputs'], { 'engagement_construire': parseInt(value) })
                object.departementid = parseInt(departement)
            }

            dispatch(setSimulateurObject(object));

        } else {

            // process child simu

        }
    }

    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

                <TextfieldRemarques handleChange={handleChangeRemarque} />

                <p style={styles.cardSubTitle}>
                    (Acte tarifé au titre du Tableau 5, n° 113). Il s’agit ici de la première opération du Crédit-bail immobilier ou Leasing ordinaire.
                </p>

                {props?.isParent === true && props.departements !== "" ?
                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            Département :
                        </div>

                        <SelectDepartement items={props.departements} nameField={"name"} defaultValue={currUserDep} optionValue={"id"}
                            handleChange={handleChange} setter={setDepartement} />
                    </div>
                    :
                    <></>
                }

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Quel est le prix de vente exprimé dans l’acte ?
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer le montant"}
                        setter={setMontantVente}
                    />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        S’agit-il d’un terrain à bâtir ?
                    </div>
                    <div>
                        <ButtonYesno handleChange={handleChange} setter={setTerrain} fieldUndefined={false}
                            defaultValue={0} />
                    </div>
                </div>

                {
                    parseInt(terrain_a_batir) === 1 ?
                        <div style={styles.cardRow}>
                            <div style={styles.cardElemVerticalyCenter}>
                                Y a-t-il un engagement de construire ?
                            </div>
                            <ButtonYesno handleChange={handleChange} setter={setEngagementConstruire} fieldUndefined={false}
                                defaultValue={0} />
                        </div>
                        :
                        <></>
                }

            </div>

        </div>
    )
}

export default Simulateur_41;
