import React, { useState } from "react";
import axios from "axios";
import Modal from "react-modal";
import "../Login.css";
import Colors from "../../../useclass/colors";
import { API_BASE_URL } from "../../../config";

// Styles pour la modal
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: Colors.white,
    boxShadow: "2px 2px 1px rgba(0,0,0, 0.2)",
    borderRadius: 7,
    width: "70%", // Adjust the width to make the modal larger
    height: "50%", // Adjust the height as needed
    maxWidth: "850px", // Optional: Set a max width for larger screens
  },
  overlay: {
    zIndex: 999,
  },
};

const styles = {
  textfieldLogin: {
    marginTop: 20,
    height: 45,
    width: "70%",
    marginBottom: 15,
    textAlign: "center",
    borderRadius: 10,
    border: "1px lightgrey solid",
  },
  containerInput: {},
  logoIdentifiant: {
    position: "absolute",
    right: 10,
    top: 12,
    cursor: "pointer",
  },
  container: {
    //height: 100,
    width: "100%",
    height: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    display:"flex",
    alignItems:"center"
  },
  containerItems: {
    textAlign: "center",
  },
  buttonLogin: {
    paddingTop: 14,
    paddingBottom: 10,
    fontSize: 18,
    width: "72%",
    marginBottom: 0,
    backgroundColor: Colors.primaryBlue,
    borderRadius: 7,
    color: Colors.white,
    border: "none",
    cursor: "pointer",
    boxShadow: "2px 2px 1px rgba(0,0,0, 0.2)",
  },
  buttonText: {
    color: "#fff",
    fontSize: 17,
    fontFamily: "HelveticaNeue-Bold, Helvetica Neue",
    fontWeight: 700,
  },
  errorMessage: {
    color: "red",
    fontWeight:"bold",
    marginTop: 30,
    marginBottom:30
  },
  message: {
    color: "#5e91b6",
    fontWeight:"bold",
    marginTop: 30,
    marginBottom:30
  },
  messageContainerMdpOublie: {
    padding: "0px 20px 0px 20px",
  },
  titleContainerMdpOublie: {
    color: Colors.primaryBlue,
    fontSize: 26,
    marginTop: 10,
    marginBottom: 0,
  },
};

Modal.setAppElement("#root");

export const ForgotPasswordModal = ({ modalIsOpen, modalIsClose }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [mailsend, setMailSend] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const resetModalState = () => {
    setEmail("");
    setMessage("");
    setMailSend(false);
    setIsError(false);
  }; 

  const handleSubmit = async (e) => {
    if (mailsend === false) {
      setMailSend(true);

      try {
        const response = await axios.post(`${API_BASE_URL}/users/requestPasswordReset`, {
          Email: email
        }, 
        {
          headers: {
            sites: 'immo'
          }
        }
      );
        if (response.status === 200) {
          setIsError(false);
          setMessage(
            "Votre demande est envoyée. Si l'adresse que vous avez fournie correspond à un compte existant, vous recevrez un e-mail contenant les instructions pour réinitialiser votre mot de passe. Veuillez vérifier votre boîte de réception et suivre les instructions. N'oubliez pas de vérifier vos spams."
          );
          setTimeout(() => {
            modalIsClose();
            resetModalState();
          }, 50000);
        } else {
          setIsError(true);
          setMessage("Une erreur est survenue. Veuillez réessayer.");
        }
      } catch (error) {
        setIsError(true);
        setMessage("Une erreur est survenue. Veuillez réessayer.");
      }
    }
  };

  const handlemodalIsClose = () => {
    modalIsClose();
    resetModalState();
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={handlemodalIsClose}
      style={customStyles}
      contentLabel="Réinitialisation du mot de passe"
    >
      <div style={styles.container}>
        <div style={styles.containerItems}>
          <div style={styles.titleContainerMdpOublie}>Mot de passe oublié ?</div>
          <br />
          <div style={styles.messageContainerMdpOublie}>
            Merci d'indiquer votre adresse e-mail ci-dessous. Si celle-ci correspond à un compte existant, vous recevrez
            un e-mail avec un lien pour réinitialiser votre mot de passe.
          </div>
          <br />
          <div style={styles.messageContainerMdpOublie}>Dans le cas contraire, contactez-nous à l'adresse <a href="mailto:support@langloys.com">support@langloys.com</a>.</div>
          <div>
            <div style={styles.containerInput}>
              <input
                type="email"
                style={styles.textfieldLogin}
                placeholder="Email"
                value={email}
                onChange={handleEmailChange}
                required
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x"
                style={styles.logoIdentifiant}
                onClick={handlemodalIsClose}
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </div>

            <button
              style={styles.buttonLogin}
              id="connexion"
              onClick={(e) => {
                handleSubmit();
              }}
              type={"submit"}
            >
              <span style={styles.buttonText}>Envoyer</span>
            </button>
          </div>
          {message && <p style={isError ? styles.errorMessage : styles.message}>{message}</p>}
        </div>
      </div>
    </Modal>
  );
};
