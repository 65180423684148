import React, { useEffect, useState } from "react";
import Colors from "../../useclass/colors";
import Pdf2 from "../../medias/fiches/Fiscalite_SAFER.pdf";
import Pdf3 from "../../medias/fiches/Specificites_alsace_moselle.pdf";
import Pdf4 from "../../medias/fiches/Check_list_plus_values.pdf";
import Pdf6 from "../../medias/fiches/Ce_qu_il_ne_faut_pas_oublier_lors_d_une_vente_(check-list).pdf";
import Pdf11 from "../../medias/fiches/Diagnostic_immobilier_le_dossier_de_diagnostic_technique_(DDT).pdf";
import Pdf12 from "../../medias/fiches/Droits_de_mutation_a_titre_onereux_(DMTO).pdf";
import Pdf16 from "../../medias/fiches/Le_bail_professionnel_et_le_bail_commercial.pdf";
import Pdf17 from "../../medias/fiches/La_fiscalite_immobiliere_quel_taux_et_quel_droit_appliquer.pdf";
import Pdf18 from "../../medias/fiches/La_location_pour_les_particuliers_quel_bail.pdf";
import Footer from "../../layouts/footer/footer";
import InfosFooter from "../../layouts/infosfooter/infosfooter";

function FichesImmo(props) {
  const styles = {
    container: {
      padding: 0,
      margin: 0,
      width: "100vw",
      minHeight: "auto",
    },
    card: {
      backgroundColor: Colors.white,
      boxShadow: "2px 2px 1px rgba(0,0,0, 0.2)",
      borderRadius: 5,
      width: "90%",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: 150,
      paddingBottom: 50,
      paddingLeft: 50,
      minHeight: "30vh",
    },
    cardTitle: {
      fontWeight: "normal",
      color: "#275d83",
      textAlign: "center",
      lineHeight: "1.4em",
      paddingTop: 20,
      marginTop: 5,
      marginBottom: 30,
    },
    cardSubTitle: {
      fontSize: 18,
      marginLeft: 35,
    },
    footer:{
      left: 0,
      bottom: 0,
    },
    listElem: {
      lineHeight: 2.5,
      cursor: "pointer",
    },
    picto: {
      marginRight: 20,
    },
  };

  const pictoFiche = (
    <svg
      id="Calque_1"
      data-name="Calque 1"
      xmlns="http://www.w3.org/2000/svg"
      width="11.69"
      height="16.14"
      viewBox="0 0 11.69 16.14"
      style={styles.picto}
    >
      <path
        d="m11.13,14.51c0,.59-.48,1.07-1.07,1.07H1.63c-.59,0-1.07-.48-1.07-1.07V1.63c0-.59.48-1.07,1.07-1.07h6.65c.15,0,.3.06.4.17l2.29,2.3c.11.11.17.25.17.4v11.09Zm-.79-11.41h-1.75v-1.84c0-.15-.13-.28-.28-.28s-.28.12-.28.28v2.12c0,.15.12.28.28.28h2.03c.15,0,.28-.12.28-.28s-.12-.28-.28-.28h0Zm1.02-.48L9.07.33c-.21-.21-.5-.33-.8-.33H1.63C.73,0,0,.73,0,1.63v12.89c0,.89.73,1.62,1.63,1.62h8.44c.9,0,1.62-.73,1.62-1.62V3.42c0-.3-.12-.58-.33-.79Z"
        fill="#5a8aac"
      />
    </svg>
  );

  return (
    <>
      <div style={styles.container}>
        <div style={styles.card}>
          <h1 style={styles.cardTitle}>Fiches Pratiques Immobilier</h1>
          <ul>
            <div
              style={styles.listElem}
              onClick={() => window.open(Pdf2, "_blank")}
            >
              {pictoFiche}
              La fiscalité des SAFER
            </div>

            <div
              style={styles.listElem}
              onClick={() => window.open(Pdf3, "_blank")}
            >
              {pictoFiche}
              Les spécificités des frais d'actes notariés en Alsace-Moselle
            </div>

            <div
              style={styles.listElem}
              onClick={() => window.open(Pdf4, "_blank")}
            >
              {pictoFiche}
              Check-list sur les plus-values
            </div>
            <div
              style={styles.listElem}
              onClick={() => window.open(Pdf6, "_blank")}
            >
              {pictoFiche}
              Check-list lors d'une vente
            </div>
            <div
              style={styles.listElem}
              onClick={() => window.open(Pdf11, "_blank")}
            >
              {pictoFiche}
              Les diagnostics immobiliers
            </div>
            <div
              style={styles.listElem}
              onClick={() => window.open(Pdf12, "_blank")}
            >
              {pictoFiche}
              Les DMTO au 1er Juin 2023
            </div>

            <div
              style={styles.listElem}
              onClick={() => window.open(Pdf16, "_blank")}
            >
              {pictoFiche}
              Le bail professionnel et le bail commercial
            </div>
            <div
              style={styles.listElem}
              onClick={() => window.open(Pdf17, "_blank")}
            >
              {pictoFiche}
              La fiscalité immobilière, quel taux et quel droit appliquer ?
            </div>
            <div
              style={styles.listElem}
              onClick={() => window.open(Pdf18, "_blank")}
            >
              {pictoFiche}
              La location pour les particuliers, quel bail ?
            </div>
          </ul>
        </div>
      </div>
      <div style={styles.footer}>
        <InfosFooter />
        <Footer />
      </div>
    </>
  );
}

export default FichesImmo;
