import {useCallback, useState} from "react";

export const server = {
    Prod: "https://api.notaire-langloys.com",
    Test: "https://cors-anywhere.herokuapp.com/https://langloyscalc.azurewebsites.net",
    Dev: "https://langloyscalc.azurewebsites.net",
    PreProd: "https://langloysapi-preprod.azurewebsites.net",
};
const currentServer = server.Prod;
export const API_BASE_URL = currentServer;

export const ENABLE_REDUX_LOGGER = false;

export function useForceUpdate() {
    const [, setTick] = useState(0);
    const update = useCallback(() => {
        setTick((tick) => tick + 1);
    }, []);
    return update;
}

export default {};

