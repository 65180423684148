import * as React from "react";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faSpinner, faFile } from "@fortawesome/free-solid-svg-icons";

import PdfDocument from "./document.PDF";
import GeneratePdfFile from "./generate.PDF";
import Colors from "../../useclass/colors";

// import { ApiResponse, ApiData } from "./interfaces";

//const LinkPDF: React.FC = (simulateurResponse) => {
const LinkPDF = (props) => {
    const {useState} = React;

//    const initialData: Array<ApiData> | undefined = undefined;

    const [error, setError] = useState(false);
    const [requesting, setRequesting] = useState(false);
    //const [data, setData] = useState(initialData);
    const [attempts, setAttempts] = useState(0);
    const [ready, setReady] = useState(false);


    const styles = {
        button: {
            width: "calc(100% + 10px)",
            height: 35,
            borderRadius: 5,
            fontSize: 15,
            border: "none",
            backgroundColor: Colors.buttonGreen,
            color: Colors.white,
            marginTop: 10,
            cursor: "pointer",
            minWidth: 200,
            padding: 5,
        },
    }

    return (
        <>
            {!props.ready ?
                <button
                    style={styles.button}
                    onClick={(e) => {
                        setReady(true)
                        props.setReadyMain(true)
                    }}
                >

                    Générer le document PDF

                </button>
                :
                <></>
            }


            {ready ?
                props.ready ?
                    <PdfDocument
                        title="Synthèse de votre simulation"
                        fileName={"Synthèse"}
                        document={<GeneratePdfFile simulateurObject={props.simulateurObject}/>}
                    />
                    :
                    <></>
                :
                <></>
            }

        </>


    )

};

export default LinkPDF;
