import Colors from "../useclass/colors";

export const styles = {
    container: {
        width: "100%",
        color: Colors.textBlack,
        //minHeight: "75vh",
    },
    card: {
        marginTop: 15,
        marginBottom: 10,
        paddingBottom: 20,
        backgroundColor: Colors.white,
        boxShadow: "2px 2px 1px rgba(0,0,0, 0.2)",
        //width: "95%",
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 4,
        minWidth: 400,
        width: "90%",
    },
    cardDMTG:{
        backgroundColor: Colors.white,
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 4,
        minWidth: 400,
    },
    cardTitleDonateur: {
        fontWeight: "bolder",
        fontSize: 20,
        color: Colors.white,
        backgroundColor: "rgb(157 114 169)",
        textAlign: "center",
        lineHeight: "1.4em",
        padding: 10,
        marginTop: 15,
        marginBottom: 0,
        borderRadius: "4px 4px 0px 0px",
    },
    cardTitleDonataire: {
        fontWeight: "bolder",
        fontSize: 17,
        color: Colors.white,
        backgroundColor: "#71ADD9",
        textAlign: "center",
        lineHeight: "1.4em",
        padding: 10,
        marginTop: -10,
        marginRight: -10,
        marginLeft: -10,
        marginBottom: 10,
        borderRadius: "4px 4px 0px 0px",
    },
    cardSubTitle: {
        fontSize: 16,
        fontStyle: "italic",
        marginLeft: "5%",
        marginRight: "10%",
        border: "1px solid lightblue",
        paddingBottom: 10,
        paddingTop:10,
        paddingLeft:10,
        paddingRight:10,
        borderRadius:5,
    },
    cardFlexContainer: {
        display: "flex",
        flexDirection: "column",
        marginTop: 30,
        marginBottom:30,
        marginRight:15,
        marginLeft:15,
        padding: 10,
        borderRadius: 4,
        backgroundColor: Colors.white,
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        maxWidth:450,
        minWidth:"auto",
    },

    cardDonateurs: {
        display: "flex",
        flexDirection: "column",
        marginTop: 10,
        marginBottom:30,
        marginRight:30,
        marginLeft:30,
        padding: 10,
        // border: "1px solid lightblue",
        borderRadius: "0px 0px 4px 4px",
        backgroundColor: Colors.white,
    },

    cardFlex:{
        display:"flex",
        flexDirection:"row",
        justifyContent:"center",
        alignItems:"flex-start",
        flexWrap:"wrap",
        border: "1px solid rgb(157 114 169)",
        borderRadius: "0px 0px 4px 4px",
        backgroundColor: Colors.white,
        marginBottom: 20,
    },
    cardFlexSuccession:{
        display:"flex",
        flexDirection:"row",
        justifyContent:"center",
        alignItems:"flex-start",
        flexWrap:"wrap",
        border: "1px solid #275d83",
        borderRadius: "4px",
        backgroundColor: Colors.white,
        marginBottom: 20,
    },
    cardRow: {
        display: "grid",
        width: "90%",
        marginLeft: "5%",
        //  marginRight: "auto",
        gridTemplateColumns: "1fr 1fr",
        paddingTop: 10,
        paddingBottom: 10,
    },

    cardRowChild: {
        borderRadius: 4,
        backgroundColor: Colors.white,
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        width: "98%",
        marginLeft: "1%",
        paddingTop: 15,
        paddingBottom: 15,
        marginBottom: 15,
        marginTop: 15,
    },

    cardElemVerticalyCenter: {
        marginTop: "auto",
        marginBottom: "auto",
        marginRight:10,
    },

    messageInABottle: {
        width: "100%",
        margin: "auto",
        paddingTop: 10,
        paddingBottom: 10,
        textAlign:"center",
        color:"red",
    },

    ctnButton: {
        width: "90%",
        marginLeft: "auto",
        marginRight: "auto",
        paddingBottom: 30,
        paddingTop: 30,

    },
    buttonCalculate: {
        width: "100%",
        height: 35,
        borderRadius: 5,
        border: "none",
        backgroundColor: Colors.buttonGreen,
        color: Colors.white
    },
    
    elemHeight: {
        paddingLeft: 10,
        height: 35,
        border: "1px solid lightGrey",
        borderRadius: 5,
        maxWidth: 350,
        minWidth: 350,
        marginLeft: "auto",
        marginRight: "auto",

    },

    globalCard:{
        display:"flex",
        flexDirection:"row",
        justifyContent:"center",
        alignItems:"flex-start",
        flexWrap:"wrap"
    },

    dateField:{
        textAlign: "center",
        color: "#275d83",
        paddingTop: "12px",
        paddingBottom: "12px",
        paddingLeft: "5px",
        paddingRight: "5px",
        border: "none",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        borderRadius: "7px",
        width: "100%",
        maxWidth: "350px",
        minWidth: "200px",
        fontSize: "14px",
        marginLeft: "auto",
        marginRight: "auto",
    },

    cardTitle: {
        display: "grid",
        width: "90%",
        marginLeft: "5%",
        //  marginRight: "auto",
        gridTemplateColumns: "1fr 1fr",
        paddingTop: 10,
        paddingBottom: 10,
        fontWeight:"bold",
        fontSize:"19",
        color:"rgb(94, 145, 182)",
    },


};
