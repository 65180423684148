import Colors from "../../../useclass/colors";
import React, {useEffect, useState} from "react";
import {setSimulateurObject} from "../../../actions/simulateur.action";
import {useDispatch} from 'react-redux';
import {styles} from "../../../stylesheets/simulateurs.css";
import Simulateur84 from "../simulateur.A1.12/simulateur.A1.12";
import Simulateur89 from "../simulateur.A2.15/simulateur.A2.15";
import SimulateurA216 from "../simulateur.A2.16/simulateur.A2.16";
import ButtonYesno from "../../../components/buttons/button.yesno.js";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import SelectDepartement from "../../../components/selects/select.departement";
import { act } from "react-dom/test-utils";
import {ToolTipPerso} from "../../../components/selects/tooltip_perso";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";

// Vente d'immeuble (régime de la copropriété) ou de locaux bâtis, affectés à tous usages, régime DMTO - hors champs de TVA, selon le département
// simulateur id 2

function Simulateur_A1_2(props, simulateur, isParent, departements) {
    const dispatch = useDispatch();

    let currUser = JSON.parse(localStorage.getItem("user"));
    let currUserDep = 0
    if (currUser.societe_zip.startsWith("97")){
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 3);
        currUserDep = parseInt(trimedZip)
    } else {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 2);
        currUserDep = parseInt(trimedZip)
    }

    const [departement, setDepartement] = useState(0)
    const [promesse, setPromesse] = useState(0)
    const [acteEnMain, setActeEnMain] = useState(0)
    const [debour, setDebour] = useState(2)
    const [pretHypo, setPretHypo] = useState(0)
    const [montantPretHp, setMontantPretHp] = useState(0)
    const [sansGarantieHypo, setSansGarantieHypo] = useState(0)
    const [avecGarantieHypo, setAvecGarantieHypo] = useState(0)
    const [simu89, setSimu89] = useState(null)
    const [simu84, setSimu84] = useState(null)
    const currentIdSimu = 2
    const [simuA216, setSimuA216] = useState(null)
    const [montantVente, setMontantVente] = useState(0)
    const [montantMeuble, setMontantMeuble] = useState(0)
    const [montantPromesse, setMontantPromesse] = useState(0)
    const [montantUrbanisme, setMontantUrbanisme] = useState(0)
    const [taxeIleDeFrance, setTaxeIleDeFrance] = useState(0)
    const [zrr, setZrr] = useState(0)
    const [urbanisme, setUrbanisme] = useState(0)
    const [dependances, setDependancesState] = useState([])

    const [dep1, setDep1] = useState({})

    const handleChange = async (setter, value) => {
        if (setter === setDepartement) {
            setDepartement(parseInt(value))
        } else setter(value);
        setInRedux(setter, value)
    };

    const handleChangeRemarque = async (value) => {
        localStorage.setItem("remarque", value)
    }

    useEffect(() => {
        if (simu89 !== undefined && simu89 !== null)
            setDependances(simu89);
    }, [simu89]);

    useEffect(() => {
        if (simu84 !== undefined && simu84 !== null)
            setDependances(simu84);
    }, [simu84]);


    useEffect(() => {
        if (simuA216 !== undefined && simuA216 !== null)
            setDependances(simuA216);
    }, [simuA216]);

    // useEffect(() => {
    //     localStorage.setItem("remarque", remarque)
    // }, [remarque]);

    function setDependances(simu) {
        setInRedux("dependance", simu)
    }

    function setInRedux(setter, value) {

        let object = {
            "simulateurid": currentIdSimu,
            "departementid": 0,
            "inputs": {},
            "dependances": [],
        };

        if (setter === setPretHypo) {
            if (value === 0) {
                setSimu84(null)
                setSimu89(null)
                setSimuA216(null)
                setDependancesState([])
                Object.assign(object['inputs'], {'montant_vente': parseInt(montantVente)})
                Object.assign(object['inputs'], {'montant_meuble': parseInt(montantMeuble)})
                Object.assign(object['inputs'], {'montant_promesse': parseInt(montantPromesse)})
                Object.assign(object['inputs'], {'montant_urbanisme': parseInt(montantUrbanisme)})
                Object.assign(object['inputs'], {'taxe_ile_de_france': parseInt(taxeIleDeFrance)})
                Object.assign(object['inputs'], {'urbanisme': parseInt(urbanisme)})
                Object.assign(object['inputs'], {'zrr': parseInt(zrr)})
                Object.assign(object['inputs'], {'promesse': parseInt(promesse)})
                Object.assign(object['inputs'], {'acte_en_main': parseInt(acteEnMain)})
                object.dependances = []
                object.departementid = parseInt(departement)
            } else {
                Object.assign(object['inputs'], {'montant_vente': parseInt(montantVente)})
                Object.assign(object['inputs'], {'montant_meuble': parseInt(montantMeuble)})
                Object.assign(object['inputs'], {'montant_promesse': parseInt(montantPromesse)})
                Object.assign(object['inputs'], {'montant_urbanisme': parseInt(montantUrbanisme)})
                Object.assign(object['inputs'], {'taxe_ile_de_france': parseInt(taxeIleDeFrance)})
                Object.assign(object['inputs'], {'urbanisme': parseInt(urbanisme)})
                Object.assign(object['inputs'], {'zrr': parseInt(zrr)})
                Object.assign(object['inputs'], {'promesse': parseInt(promesse)})
                Object.assign(object['inputs'], {'acte_en_main': parseInt(acteEnMain)})
                object.dependances = dependances
                object.departementid = parseInt(departement)
            }
        }

        if (setter === setSansGarantieHypo) {
            if (value === 0) {
                const index = dependances.findIndex(obj => obj.simulateurid === 89);
                dependances?.splice(index, 1)
                setSimu89(null)
            }
                Object.assign(object['inputs'], {'montant_vente': parseInt(montantVente)})
                Object.assign(object['inputs'], {'montant_meuble': parseInt(montantMeuble)})
                Object.assign(object['inputs'], {'montant_promesse': parseInt(montantPromesse)})
                Object.assign(object['inputs'], {'montant_urbanisme': parseInt(montantUrbanisme)})
                Object.assign(object['inputs'], {'taxe_ile_de_france': parseInt(taxeIleDeFrance)})
                Object.assign(object['inputs'], {'urbanisme': parseInt(urbanisme)})
                Object.assign(object['inputs'], {'zrr': parseInt(zrr)})
                Object.assign(object['inputs'], {'promesse': parseInt(promesse)})
                Object.assign(object['inputs'], {'acte_en_main': parseInt(acteEnMain)})
            object.dependances = dependances
                object.departementid = parseInt(departement)
        }

        if (setter === setAvecGarantieHypo) {
            if (value === 0) {
                const index = dependances.findIndex(obj => obj.simulateurid === 90);
                dependances?.splice(index, 1)
                setSimuA216(null)
            }
                Object.assign(object['inputs'], {'montant_vente': parseInt(montantVente)})
                Object.assign(object['inputs'], {'montant_meuble': parseInt(montantMeuble)})
                Object.assign(object['inputs'], {'montant_promesse': parseInt(montantPromesse)})
                Object.assign(object['inputs'], {'montant_urbanisme': parseInt(montantUrbanisme)})
                Object.assign(object['inputs'], {'taxe_ile_de_france': parseInt(taxeIleDeFrance)})
                Object.assign(object['inputs'], {'urbanisme': parseInt(urbanisme)})
                Object.assign(object['inputs'], {'zrr': parseInt(zrr)})
                Object.assign(object['inputs'], {'promesse': parseInt(promesse)})
                Object.assign(object['inputs'], {'acte_en_main': parseInt(acteEnMain)})
            object.dependances = dependances
                object.departementid = parseInt(departement)
        }

        if (setter === "dependance") {
            if (dependances.length > 0)
                object.dependances = dependances

            if (value !== undefined) {

                let validator = false

                for (let i = 0; i < object["dependances"].length; i++) {
                    if (object['dependances'][i]?.simulateurid === value.simulateurid) {
                        object.dependances[i] = value
                        validator = true
                    }
                }

                if (validator === false) {
                    object.dependances.push(value)
                }

                setDependancesState(object.dependances)

                Object.assign(object['inputs'], {'montant_vente': parseInt(montantVente)})
                Object.assign(object['inputs'], {'montant_meuble': parseInt(montantMeuble)})
                Object.assign(object['inputs'], {'montant_promesse': parseInt(montantPromesse)})
                Object.assign(object['inputs'], {'montant_urbanisme': parseInt(montantUrbanisme)})
                Object.assign(object['inputs'], {'taxe_ile_de_france': parseInt(taxeIleDeFrance)})
                Object.assign(object['inputs'], {'urbanisme': parseInt(urbanisme)})
                Object.assign(object['inputs'], {'zrr': parseInt(zrr)})
                Object.assign(object['inputs'], {'promesse': parseInt(promesse)})
                Object.assign(object['inputs'], {'acte_en_main': parseInt(acteEnMain)})
                object.departementid = parseInt(departement)
            }
        }


        if (setter === setMontantVente) {
            Object.assign(object['inputs'], {'montant_vente': parseInt(value)})
            Object.assign(object['inputs'], {'montant_meuble': parseInt(montantMeuble)})
            Object.assign(object['inputs'], {'montant_promesse': parseInt(montantPromesse)})
            Object.assign(object['inputs'], {'montant_urbanisme': parseInt(montantUrbanisme)})
            Object.assign(object['inputs'], {'taxe_ile_de_france': parseInt(taxeIleDeFrance)})
            Object.assign(object['inputs'], {'urbanisme': parseInt(urbanisme)})
            Object.assign(object['inputs'], {'zrr': parseInt(zrr)})
            Object.assign(object['inputs'], {'acte_en_main': parseInt(acteEnMain)})
            Object.assign(object['inputs'], {'promesse': parseInt(promesse)})
            object.dependances = dependances
            object.departementid = parseInt(departement)
        }

        if (setter === setMontantMeuble) {
            Object.assign(object['inputs'], {'montant_meuble': parseInt(value)})
            Object.assign(object['inputs'], {'montant_vente': parseInt(montantVente)})
            Object.assign(object['inputs'], {'montant_promesse': parseInt(montantPromesse)})
            Object.assign(object['inputs'], {'montant_urbanisme': parseInt(montantUrbanisme)})
            Object.assign(object['inputs'], {'taxe_ile_de_france': parseInt(taxeIleDeFrance)})
            Object.assign(object['inputs'], {'urbanisme': parseInt(urbanisme)})
            Object.assign(object['inputs'], {'zrr': parseInt(zrr)})
            Object.assign(object['inputs'], {'acte_en_main': parseInt(acteEnMain)})
            Object.assign(object['inputs'], {'promesse': parseInt(promesse)})
            object.dependances = dependances
            object.departementid = parseInt(departement)
        }

        if (setter === setMontantPromesse) {
            Object.assign(object['inputs'], {'montant_promesse': parseInt(value)})
            Object.assign(object['inputs'], {'montant_meuble': parseInt(montantMeuble)})
            Object.assign(object['inputs'], {'montant_vente': parseInt(montantVente)})
            Object.assign(object['inputs'], {'montant_urbanisme': parseInt(montantUrbanisme)})
            Object.assign(object['inputs'], {'taxe_ile_de_france': parseInt(taxeIleDeFrance)})
            Object.assign(object['inputs'], {'urbanisme': parseInt(urbanisme)})
            Object.assign(object['inputs'], {'zrr': parseInt(zrr)})
            Object.assign(object['inputs'], {'acte_en_main': parseInt(acteEnMain)})
            Object.assign(object['inputs'], {'promesse': parseInt(promesse)})
            object.dependances = dependances
            object.departementid = parseInt(departement)
        }

        if (setter === setMontantUrbanisme) {
            Object.assign(object['inputs'], {'montant_urbanisme': parseInt(value)})
            Object.assign(object['inputs'], {'montant_promesse': parseInt(montantPromesse)})
            Object.assign(object['inputs'], {'montant_meuble': parseInt(montantMeuble)})
            Object.assign(object['inputs'], {'montant_vente': parseInt(montantVente)})
            Object.assign(object['inputs'], {'taxe_ile_de_france': parseInt(taxeIleDeFrance)})
            Object.assign(object['inputs'], {'urbanisme': parseInt(urbanisme)})
            Object.assign(object['inputs'], {'acte_en_main': parseInt(acteEnMain)})
            Object.assign(object['inputs'], {'zrr': parseInt(zrr)})
            Object.assign(object['inputs'], {'promesse': parseInt(promesse)})
            object.dependances = dependances
            object.departementid = parseInt(departement)
        }

        if (setter === setDepartement) {
            Object.assign(object['inputs'], {'montant_promesse': parseInt(montantPromesse)})
            Object.assign(object['inputs'], {'montant_meuble': parseInt(montantMeuble)})
            Object.assign(object['inputs'], {'montant_vente': parseInt(montantVente)})
            Object.assign(object['inputs'], {'montant_urbanisme': parseInt(montantUrbanisme)})
            Object.assign(object['inputs'], {'taxe_ile_de_france': parseInt(taxeIleDeFrance)})
            Object.assign(object['inputs'], {'urbanisme': parseInt(urbanisme)})
            Object.assign(object['inputs'], {'acte_en_main': parseInt(acteEnMain)})
            Object.assign(object['inputs'], {'zrr': parseInt(zrr)})
            Object.assign(object['inputs'], {'promesse': parseInt(promesse)})
            object.dependances = dependances
            object.departementid = parseInt(value)
        }

        if (setter === setTaxeIleDeFrance) {
            Object.assign(object['inputs'], {'montant_promesse': parseInt(montantPromesse)})
            Object.assign(object['inputs'], {'montant_meuble': parseInt(montantMeuble)})
            Object.assign(object['inputs'], {'montant_vente': parseInt(montantVente)})
            Object.assign(object['inputs'], {'montant_urbanisme': parseInt(montantUrbanisme)})
            Object.assign(object['inputs'], {'zrr': parseInt(zrr)})
            Object.assign(object['inputs'], {'acte_en_main': parseInt(acteEnMain)})
            Object.assign(object['inputs'], {'urbanisme': parseInt(urbanisme)})
            Object.assign(object['inputs'], {'taxe_ile_de_france': parseInt(value)})
            Object.assign(object['inputs'], {'promesse': parseInt(promesse)})
            object.dependances = dependances
            object.departementid = parseInt(departement)
        }

        if (setter === setZrr) {
            Object.assign(object['inputs'], {'montant_promesse': parseInt(montantPromesse)})
            Object.assign(object['inputs'], {'montant_meuble': parseInt(montantMeuble)})
            Object.assign(object['inputs'], {'montant_vente': parseInt(montantVente)})
            Object.assign(object['inputs'], {'montant_urbanisme': parseInt(montantUrbanisme)})
            Object.assign(object['inputs'], {'zrr': parseInt(value)})
            Object.assign(object['inputs'], {'taxe_ile_de_france': parseInt(taxeIleDeFrance)})
            Object.assign(object['inputs'], {'urbanisme': parseInt(urbanisme)})
            Object.assign(object['inputs'], {'acte_en_main': parseInt(acteEnMain)})
            Object.assign(object['inputs'], {'promesse': parseInt(promesse)})
            object.dependances = dependances
            object.departementid = parseInt(departement)
        }
        if (setter === setUrbanisme) {
            Object.assign(object['inputs'], {'montant_promesse': parseInt(montantPromesse)})
            Object.assign(object['inputs'], {'montant_meuble': parseInt(montantMeuble)})
            Object.assign(object['inputs'], {'montant_vente': parseInt(montantVente)})
            Object.assign(object['inputs'], {'montant_urbanisme': parseInt(montantUrbanisme)})
            Object.assign(object['inputs'], {'zrr': parseInt(zrr)})
            Object.assign(object['inputs'], {'taxe_ile_de_france': parseInt(taxeIleDeFrance)})
            Object.assign(object['inputs'], {'urbanisme': parseInt(value)})
            Object.assign(object['inputs'], {'promesse': parseInt(promesse)})
            Object.assign(object['inputs'], {'acte_en_main': parseInt(acteEnMain)})
            object.dependances = dependances
            object.departementid = parseInt(departement)
        }

        if (setter === setPromesse) {
            Object.assign(object['inputs'], {'montant_promesse': parseInt(montantPromesse)})
            Object.assign(object['inputs'], {'montant_meuble': parseInt(montantMeuble)})
            Object.assign(object['inputs'], {'montant_vente': parseInt(montantVente)})
            Object.assign(object['inputs'], {'montant_urbanisme': parseInt(montantUrbanisme)})
            Object.assign(object['inputs'], {'zrr': parseInt(zrr)})
            Object.assign(object['inputs'], {'taxe_ile_de_france': parseInt(taxeIleDeFrance)})
            Object.assign(object['inputs'], {'urbanisme': parseInt(urbanisme)})
            Object.assign(object['inputs'], {'promesse': parseInt(value)})
            Object.assign(object['inputs'], {'acte_en_main': parseInt(acteEnMain)})
            object.dependances = dependances
            object.departementid = parseInt(departement)
        }

        if (setter === setActeEnMain) {
            Object.assign(object['inputs'], {'montant_promesse': parseInt(montantPromesse)})
            Object.assign(object['inputs'], {'montant_meuble': parseInt(montantMeuble)})
            Object.assign(object['inputs'], {'montant_vente': parseInt(montantVente)})
            Object.assign(object['inputs'], {'montant_urbanisme': parseInt(montantUrbanisme)})
            Object.assign(object['inputs'], {'zrr': parseInt(zrr)})
            Object.assign(object['inputs'], {'taxe_ile_de_france': parseInt(taxeIleDeFrance)})
            Object.assign(object['inputs'], {'urbanisme': parseInt(urbanisme)})
            Object.assign(object['inputs'], {'promesse': parseInt(promesse)})
            Object.assign(object['inputs'], {'acte_en_main': parseInt(value)})
            object.dependances = dependances
            object.departementid = parseInt(departement)
        }

        if (props.isParent) {
            dispatch(setSimulateurObject(object));
        } else {

        }
    }

    const depsTaxeIleFrance = [75, 77, 78, 91, 92, 93, 94, 95]
    const depsZrr = [14, 38, 51, 71]

    return (
        <div style={styles.container}>
            <div style={styles.card}>

                <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id}/>

                <TextfieldRemarques handleChange={handleChangeRemarque}/>

                {props?.isParent === true && props.departements !== "" ?
                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            Département :
                        </div>

                        <SelectDepartement items={props.departements} nameField={"name"} defaultValue={currUserDep} optionValue={"id"}
                                      handleChange={handleChange} setter={setDepartement}/>
                    </div>
                    :
                    <></>
                }

                {
                    depsTaxeIleFrance.includes(departement) ?
                        <div style={styles.cardRow}>
                            <div style={styles.cardElemVerticalyCenter}>
                                Mutation soumise à la taxe additionnelle de 0.6% -art 599 sexies du CGI ?
                                <ToolTipPerso title={"Attention : Cette taxe ne s’applique que dans certains cas. Se référer au texte en vigueur qui les précise : art 1599 sexies du CGI. Taxe additionnelle aux droits d'enregistrement ou à la taxe de publicité foncière exigible sur les mutations à titre onéreux de locaux mentionnés à l'article L. 520-1 du code de l'urbanisme et situés en IDF. Le taux de la taxe est fixé à 0,6 %. Cette taxe s’applique aux : locaux à usage de bureaux ; locaux commerciaux ; locaux de stockage. Cette taxe ne s'applique pas : aux mutations à titre onéreux soumises à la taxe sur la valeur ajoutée ; aux mutations exonérées de droits d'enregistrement ou de taxe de publicité foncière ; aux mutations d'immeubles sis hors de la région d'Île-de-France, y compris lorsque les actes translatifs de ces propriétés sont soumis à la formalité de l'enregistrement en Île-de-France ; aux opérations qui ne constituent pas une vente ou une opération assimilée."}/>
                            </div>
                            <div>
                                <ButtonYesno handleChange={handleChange} setter={setTaxeIleDeFrance}
                                             fieldUndefined={false} defaultValue={0}/>
                            </div>
                        </div>
                        :
                        <>
                            {
                                depsZrr.includes(departement) ?
                                    <div style={styles.cardRow}>
                                        <div style={styles.cardElemVerticalyCenter}>
                                            Habitation située dans une zone de revitalisation rurale ? (faisant l'objet
                                            d'une réduction de
                                            droits)
                                        </div>
                                        <div>
                                            <ButtonYesno handleChange={handleChange} setter={setZrr}
                                                         fieldUndefined={false} defaultValue={0}/>
                                        </div>
                                    </div>
                                    :
                                    <>
                                    </>
                            }

                        </>


                }


                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        La vente est-elle consentie "Acte en main" ?
                    </div>
                    <div>
                        <ButtonYesno handleChange={handleChange} setter={setActeEnMain} fieldUndefined={false}
                                     defaultValue={0}/>
                    </div>
                </div>


                <div style={styles.cardRow}>
                    {
                      acteEnMain == 0 ?  <div style={styles.cardElemVerticalyCenter}>
                      Quel est le montant de la vente  ?
                  </div>
                   :   <div style={styles.cardElemVerticalyCenter}>
                        Quel est le montant de la vente  "acte en main" ?
                    </div>
                    }
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer le montant"}
                        setter={setMontantVente}
                    />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Quel est le montant des meubles (y compris) :
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer le montant"}
                        setter={setMontantMeuble}
                    />
                </div>


                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Y a-t-il une promesse de vente reçue sous forme authentique :
                    </div>
                    <div>
                        <ButtonYesno handleChange={handleChange} setter={setPromesse} fieldUndefined={false}
                                     defaultValue={0}/>
                    </div>
                </div>
                {
                    parseInt(promesse) === 1 ?
                        <div style={styles.cardRow}>
                            <div style={styles.cardElemVerticalyCenter}>
                                Indiquer le montant HT des honoraires de rédaction (s'il y a lieu) :
                            </div>
                            <TextfieldNumber
                                handleChange={handleChange}
                                type={"number"}
                                placeholder={"Indiquer le montant"}
                                setter={setMontantPromesse}
                            />
                        </div>
                        :
                        <></>
                }


                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Y a-t-il un urbanisme demandé à un tiers, ou d'autres débours à prévoir ?
                        <ToolTipPerso title={"Les débours sur les ventes comprennent une somme de 120 euros représentant le coût moyen d'obtention d'un certificat d'urbanisme complet. Pour retirer ce coût, répondre 'non' à la question 'urbanisme demandé à un tiers'."}/>
                    </div>
                    <div>
                        <ButtonYesno handleChange={handleChange} setter={setUrbanisme} fieldUndefined={true}
                                     defaultValue={2}
                        />
                    </div>
                </div>

                {
                    parseInt(urbanisme) === 1 ?
                        <div style={styles.cardRow}>
                            <div style={styles.cardElemVerticalyCenter}>
                                Montant de l'urbanisme :
                            </div>
                            <TextfieldNumber
                                handleChange={handleChange}
                                type={"number"}
                                placeholder={"Indiquer le montant"}
                                setter={setMontantUrbanisme}
                            />
                        </div>
                        :
                        <></>
                }


                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Y'a-t-il un prêt hypothécaire ?
                    </div>
                    <div>
                        <ButtonYesno handleChange={handleChange} setter={setPretHypo} fieldUndefined={false}
                                     defaultValue={0}/>
                    </div>
                </div>

                {
                    parseInt(pretHypo) === 1 ?
                        <>
                            <Simulateur84
                                // simulateur={props.simulateur}
                                // isParent={false}
                                // departements={departements}
                                contentDep={setDep1}
                                simulateur={props.simulateur}
                                isParent={false}
                                departementId={departement}
                                dependances={setSimu84}
                            />

                            <div style={styles.cardRow}>
                                <div style={styles.cardElemVerticalyCenter}>
                                    Y'a-t-il un cautionnement sans garantie hypothécaire ?
                                </div>
                                <div>
                                    <ButtonYesno handleChange={handleChange} setter={setSansGarantieHypo}
                                                 fieldUndefined={false}
                                                 defaultValue={0}
                                    />
                                </div>
                            </div>


                            {
                                parseInt(sansGarantieHypo) === 1 ?
                                    <>
                                        <Simulateur89
                                            simulateur={props.simulateur}
                                            isParent={false}
                                            departementId={departement}
                                            dependances={setSimu89}
                                        />
                                    </>
                                    :
                                    <></>
                            }

                            <div style={styles.cardRow}>
                                <div style={styles.cardElemVerticalyCenter}>
                                    Y'a-t-il un cautionnement avec garantie hypothécaire ?
                                </div>
                                <div>
                                    <ButtonYesno handleChange={handleChange} setter={setAvecGarantieHypo}
                                                 fieldUndefined={false}
                                                 defaultValue={0}
                                    />
                                </div>
                            </div>


                            {
                                parseInt(avecGarantieHypo) === 1 ?
                                    <>
                                        <SimulateurA216
                                            simulateur={props.simulateur}
                                            isParent={false}
                                            departementId={departement}
                                            dependances={setSimuA216}
                                        />
                                    </>
                                    :
                                    <></>
                            }

                        </>
                        :
                        <></>
                }
            </div>

        </div>
    )
}

export default Simulateur_A1_2;
