import React, { useState, useEffect } from 'react';
import TextfieldNumber from "../textfields/textfield.number";
import TextfieldAbattement from "../textfields/textfield.abattement";
import { styles } from "../../stylesheets/simulateurs.css";
import SelectCommon from "../selects/select.common";
import ButtonYesno from "../buttons/button.yesno.js";
import { useDispatch } from 'react-redux';
import { setDMTGObject } from "../../actions/dmtg.action";
import { ToolTipPerso } from "../selects/tooltip_perso";

const Donations_especes = (props) => {
    const dispatch = useDispatch();

    // Ici props contient donc :
    // props.resultKey => key du tableau "results" nécessaire pour setter la bonne valeur
    // props.functionSetResults => pour lancer la fonction du main depuis le component
    // ----

    // States avec les différentes valeurs à stocker
    // Possibilité également de mettre tous les states (value1, value2....) dans un seul state sous format:
    //
    // state: {
    //      value1: {},
    //      value2: {},
    //      ...
    // }
    //
    // Dans ce cas penser à changer le callback du useEffect qui prend plus bas [value1, value2]
    // ---

    const [lienParente, setLien] = useState(0)
    //const [nomDonataire, setNomDonataire] = useState(0)
    const [loiTepa, setTepa] = useState(0)
    const [handicap, setHandicap] = useState(0)
    const [montantEspeces, setEspeces] = useState(0)
    const [abattementLegal, setAbattementLegal] = useState(0)
    const abattementTepa = "31 825 €"
    const [montantUtilise, setMontantUtilise] = useState(0)
    const [montantTepaUtilise, setMontantTepaUtilise] = useState(0)

    const handleChange = async (setter, value) => {
        setter(value)
        setInRedux(setter, value)
        if (setter === setLien) {
            const selectedLien = lienDonation.find(item => item.id === value);
            setAbattementLegal(selectedLien.valueAbattement);
        }
    };

    /*    useEffect(() => {
            if (lienParente) {
                const selectedLien = lienDonation.find(option => option.id === lienParente);
                setAbattementLegal(selectedLien.valueAbattement);
            }
        },[]);*/

    function setInRedux(setter, value) {
        let object = { "simulateurid": props.simulateurid, "inputs": {} };
        if (setter === setLien) {
            if (value === "1" || "3" || "7") {
                handleChange(setTepa, 0)
                handleChange(setMontantTepaUtilise, 0)
            }
            Object.assign(object['inputs'], { 'lien_parente_donation': parseInt(value) })
            Object.assign(object['inputs'], { 'tepa': parseInt(loiTepa) })
            Object.assign(object['inputs'], { 'handicap': parseInt(handicap) })
            Object.assign(object['inputs'], { 'montant_especes': parseInt(montantEspeces) })
            Object.assign(object['inputs'], { 'montant_utilise': parseInt(montantUtilise) })
            Object.assign(object['inputs'], { 'montant_tepa_utilise': parseInt(montantTepaUtilise) })
            Object.assign(object['inputs'], { 'donateur': parseInt(props.donateur) })
            Object.assign(object['inputs'], { 'donataire': parseInt(props.soloResultKey + 1) })
        }

        if (setter === setTepa) {
            Object.assign(object['inputs'], { 'lien_parente_donation': parseInt(lienParente) })
            Object.assign(object['inputs'], { 'tepa': parseInt(value) })
            Object.assign(object['inputs'], { 'handicap': parseInt(handicap) })
            Object.assign(object['inputs'], { 'montant_especes': parseInt(montantEspeces) })
            Object.assign(object['inputs'], { 'montant_utilise': parseInt(montantUtilise) })
            Object.assign(object['inputs'], { 'montant_tepa_utilise': parseInt(montantTepaUtilise) })
            Object.assign(object['inputs'], { 'donateur': parseInt(props.donateur) })
            Object.assign(object['inputs'], { 'donataire': parseInt(props.soloResultKey + 1) })
        }

        if (setter === setHandicap) {
            if (value === 1 && lienParente === "1") {
                handleChange(setLien, "1")
            }
            Object.assign(object['inputs'], { 'lien_parente_donation': parseInt(lienParente) })
            Object.assign(object['inputs'], { 'tepa': parseInt(loiTepa) })
            Object.assign(object['inputs'], { 'handicap': parseInt(value) })
            Object.assign(object['inputs'], { 'montant_especes': parseInt(montantEspeces) })
            Object.assign(object['inputs'], { 'montant_utilise': parseInt(montantUtilise) })
            Object.assign(object['inputs'], { 'montant_tepa_utilise': parseInt(montantTepaUtilise) })
            Object.assign(object['inputs'], { 'donateur': parseInt(props.donateur) })
            Object.assign(object['inputs'], { 'donataire': parseInt(props.soloResultKey + 1) })
        }

        if (setter === setEspeces) {
            Object.assign(object['inputs'], { 'lien_parente_donation': parseInt(lienParente) })
            Object.assign(object['inputs'], { 'tepa': parseInt(loiTepa) })
            Object.assign(object['inputs'], { 'handicap': parseInt(handicap) })
            Object.assign(object['inputs'], { 'montant_especes': parseInt(value) })
            Object.assign(object['inputs'], { 'montant_utilise': parseInt(montantUtilise) })
            Object.assign(object['inputs'], { 'montant_tepa_utilise': parseInt(montantTepaUtilise) })
            Object.assign(object['inputs'], { 'donateur': parseInt(props.donateur) })
            Object.assign(object['inputs'], { 'donataire': parseInt(props.soloResultKey + 1) })
        }
        if (setter === setMontantUtilise) {
            Object.assign(object['inputs'], { 'lien_parente_donation': parseInt(lienParente) })
            Object.assign(object['inputs'], { 'tepa': parseInt(loiTepa) })
            Object.assign(object['inputs'], { 'handicap': parseInt(handicap) })
            Object.assign(object['inputs'], { 'montant_especes': parseInt(montantEspeces) })
            Object.assign(object['inputs'], { 'montant_utilise': parseInt(value) })
            Object.assign(object['inputs'], { 'montant_tepa_utilise': parseInt(montantTepaUtilise) })
            Object.assign(object['inputs'], { 'donateur': parseInt(props.donateur) })
            Object.assign(object['inputs'], { 'donataire': parseInt(props.soloResultKey + 1) })
        }
        if (setter === setMontantTepaUtilise) {
            Object.assign(object['inputs'], { 'lien_parente_donation': parseInt(lienParente) })
            Object.assign(object['inputs'], { 'tepa': parseInt(loiTepa) })
            Object.assign(object['inputs'], { 'handicap': parseInt(handicap) })
            Object.assign(object['inputs'], { 'montant_especes': parseInt(montantEspeces) })
            Object.assign(object['inputs'], { 'montant_utilise': parseInt(montantUtilise) })
            Object.assign(object['inputs'], { 'montant_tepa_utilise': parseInt(value) })
            Object.assign(object['inputs'], { 'donateur': parseInt(props.donateur) })
            Object.assign(object['inputs'], { 'donataire': parseInt(props.soloResultKey + 1) })
        }
        //console.log(object)
        /*          if (setter === setNomDonataire) {
                        props.functionSetDonatairesList(props.soloResultKey, {"nom": value})
                    }*/
        props.functionSetSoloResults(props.soloResultKey, object)
        dispatch(setDMTGObject(object));
    }

    const lienDonation = [
        {
            id: 1,
            name: "Entre époux ou entre partenaires pacsés",
            valueAbattement: "80 724 €"
        },
        {
            id: 2,
            name: "En ligne directe - Ascendants ou enfants",
            valueAbattement: "100 000 €"
        },
        {
            id: 3,
            name: "Entre frères et sœurs",
            valueAbattement: "15 932 €"
        },
        {
            id: 4,
            name: "Aux neveux et nièces",
            valueAbattement: "7 967 €"
        },
        {
            id: 5,
            name: "Aux petits enfants",
            valueAbattement: "31 865 €"
        },
        {
            id: 6,
            name: "Aux arrières petits enfants",
            valueAbattement: "5 310 €"
        },
        {
            id: 7,
            name: "Entre parents au-dela du 4ème degré et non parents",
            valueAbattement: "0€"
        }]

    // Hook appelé à la génération de la page car aucune restriction n'est imposé aux fields value1 et value2.
    // Ce hook captera chaque modification de field et appelera la fonction du App.js "FunctionSetResults"
    // pour set la key correspondante à l'appel de component dans le state "results".

    useEffect(() => {
        if (lienParente === "1" && handicap === 1) {
            setAbattementLegal("240 049");
        } else if (lienParente === "1") {
            setAbattementLegal("80 724");
        } else if (lienParente === "2" && handicap === 1) {
            setAbattementLegal("259 325");
        } else if (lienParente === "2") {
            setAbattementLegal("100 000");
        } else if (lienParente === "3" && handicap === 1) {
            setAbattementLegal("175 257");
        } else if (lienParente === "3") {
            setAbattementLegal("15 932");
        } else if (lienParente === "4" && handicap === 1) {
            setAbattementLegal("167 286");
        } else if (lienParente === "4") {
            setAbattementLegal("7 967");
        } else if (lienParente === "5" && handicap === 1) {
            setAbattementLegal("191 190");
        } else if (lienParente === "5") {
            setAbattementLegal("31 865");
        } else if (lienParente === "6" && handicap === 1) {
            setAbattementLegal("164 635");
        } else if (lienParente === "6") {
            setAbattementLegal("5 310");
        } else if (lienParente === "7" && handicap === 1) {
            setAbattementLegal("159 325");
        } else if (lienParente === "7") {
            setAbattementLegal("0");
        }
    }, [lienParente, handicap]);

    return (
        <div style={styles.cardFlexContainer}>
            {/*<div>
                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Indiquer le nom du donataire
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"text"}
                        placeholder={"Indiquer le nom"}
                        setter={setNomDonataire}
                    />
                </div>
            </div>*/}

            <div style={styles.cardTitleDonataire}>
                Donataire {props.soloResultKey + 1}
            </div>
            <div style={styles.cardRow}>
                <div style={styles.cardElemVerticalyCenter}>
                    Quel est leur lien de parenté ?
                </div>
                <SelectCommon
                    items={lienDonation}
                    defaultValue={"1"}
                    nameField={"name"}
                    optionValue={"id"}
                    handleChange={handleChange}
                    setter={setLien} />
            </div>

            {
                parseInt(lienParente) === 2 || parseInt(lienParente) === 4 || parseInt(lienParente) === 5 || parseInt(lienParente) === 6 ?
                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            Souhaitez-vous ajouter l'abattement spécifique loi TEPA ?
                            <ToolTipPerso title={"Abattement supplémentaire de 31 865€ sur les dons de somme d'argent, soumis aux conditions suivantes : Le donateur doit, au jour de la transmission, être âgé de moins de 80ans ; Le bénificiaire doit être majeur, c'est-à-dire avoir au moins 18 ans, au jour de la transmission (ou avoir fait l'objet d'une émancipation) ; Les dons doivent être effectués en pleine propriété : aux enfants, petits-enfants ou arrière-petits-enfants; à défaut de descendance, aux neuveux et nièces; ou en cas de décès des neuveux et nièces, par représentation à des petits-neveux ou des petites nièces."} />
                        </div>
                        <ButtonYesno handleChange={handleChange} setter={setTepa} defaultValue={0} />
                    </div>
                    :
                    <></>
            }

            <div style={styles.cardRow}>
                <div style={styles.cardElemVerticalyCenter}>
                    Le bénéficiaire est-il en situation de handicap ?
                    <ToolTipPerso title={"En cas d’invalidité permanente, abattement de 159 325 € cumulable."} />
                </div>
                <ButtonYesno handleChange={handleChange} setter={setHandicap} defaultValue={0} />
            </div>

            <div style={styles.cardRow}>
                <div style={styles.cardElemVerticalyCenter}>
                    Montant de la donation lui revenant
                </div>
                <TextfieldNumber
                    handleChange={handleChange}
                    type={"number"}
                    placeholder={"Indiquer le montant"}
                    setter={setEspeces}
                />
            </div>

            <div style={styles.cardRow}>
                <div style={styles.cardElemVerticalyCenter}>
                    Abattement légal
                </div>
                <TextfieldAbattement
                    type={"number"}
                    placeholder={abattementLegal + " €"}
                />
            </div>

            {
                parseInt(loiTepa) === 1 ?
                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            Abattement TEPA
                        </div>
                        <TextfieldAbattement
                            type={"number"}
                            placeholder={abattementTepa}
                        />
                    </div>
                    :
                    <></>
            }

            <div style={styles.cardRow}>
                <div style={styles.cardElemVerticalyCenter}>
                    Part de l’abattement légal déjà utilisé
                </div>
                <TextfieldNumber
                    handleChange={handleChange}
                    type={"number"}
                    placeholder={"Indiquer le montant"}
                    setter={setMontantUtilise}
                />
            </div>

            {
                parseInt(loiTepa) === 1 ?
                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            Part de l’abattement TEPA déjà utilisé
                        </div>
                        <TextfieldNumber
                            handleChange={handleChange}
                            type={"number"}
                            placeholder={"Indiquer le montant"}
                            setter={setMontantTepaUtilise}
                        />
                    </div>
                    :
                    <></>
            }

        </div>
    );
}
    ;

export default Donations_especes;
