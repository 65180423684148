import Colors from "../../../useclass/colors";
import React, {useEffect, useState} from "react";
import {setSimulateurObject} from "../../../actions/simulateur.action";
import {useDispatch} from 'react-redux';
import {styles} from "../../../stylesheets/simulateurs.css";
import ButtonYesno from "../../../components/buttons/button.yesno.js";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import SelectDepartement from "../../../components/selects/select.departement";
import Simulateur_A1_12VEFA from "../simulateur.A1.12VEFA/simulateur.A1.12VEFA";
import SelectCommon from "../../../components/selects/select.common";
import { act } from "react-dom/test-utils";
import {ToolTipPerso} from "../../../components/selects/tooltip_perso";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";

// VEFA de 250 à 499 lots et ventes de locaux d'habitation dans le neuf
// simulateur id 16

function Simulateur_A2_6(props, simulateur, isParent, departements) {
    const dispatch = useDispatch();

    let currUser = JSON.parse(localStorage.getItem("user"));
    let currUserDep = 0
    if (currUser.societe_zip.startsWith("97")){
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 3);
        currUserDep = parseInt(trimedZip)
    } else {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 2);
        currUserDep = parseInt(trimedZip)
    }

    const [departement, setDepartement] = useState(0)
    const [promesse, setPromesse] = useState(0)
    const [acteEnMain, setActeEnMain] = useState(0)
    const [debour, setDebour] = useState(2)
    const [pretHypo, setPretHypo] = useState(0)
    const [montantPretHp, setMontantPretHp] = useState(0)
    const [montant_exigible, setMontantExigible] = useState(0)
    const [tva_concerne, setTva] = useState("0.2")
    const [simu85, setSimu85] = useState(null)
    const currentIdSimu = 16
    const [montantVente, setMontantVente] = useState(0)
    const [montantPromesse, setMontantPromesse] = useState(0)
    const [montantUrbanisme, setMontantUrbanisme] = useState(0)
    const [urbanisme, setUrbanisme] = useState(0)
    const [dependances, setDependancesState] = useState([])
    const [dep1, setDep1] = useState({})

    const handleChange = async (setter, value) => {
        if (setter === setDepartement) {
            setDepartement(parseInt(value))
        } else setter(value);
        setInRedux(setter, value)
    };

    useEffect(() => {
        if (simu85 !== undefined && simu85 !== null)
            setDependances(simu85);
    }, [simu85]);

    const handleChangeRemarque = async (value) => {
        localStorage.setItem("remarque", value)
    }

    function setDependances(simu) {
        setInRedux("dependance", simu)
    }

    function setInRedux(setter, value) {

        let object = {
            "simulateurid": currentIdSimu,
            "departementid": 0,
            "inputs": {},
            "dependances": [],
        };

        if (setter === setPretHypo) {
            if (value === 0) {
                setSimu85(null)
                setDependancesState([])
                Object.assign(object['inputs'], {'montant_vente': parseInt(montantVente)})
                Object.assign(object['inputs'], {'montant_promesse': parseInt(montantPromesse)})
                Object.assign(object['inputs'], {'montant_urbanisme': parseInt(montantUrbanisme)})
                Object.assign(object['inputs'], {'urbanisme': parseInt(urbanisme)})
                Object.assign(object['inputs'], {'promesse': parseInt(promesse)})
                Object.assign(object['inputs'], {'acte_en_main': parseInt(acteEnMain)})
                Object.assign(object['inputs'], {'montant_exigible': parseInt(montant_exigible)})
                Object.assign(object['inputs'], {'tva_concerne': parseFloat(tva_concerne)})
                object.dependances = []
                object.departementid = parseInt(departement)
            } else {
                Object.assign(object['inputs'], {'montant_vente': parseInt(montantVente)})
                Object.assign(object['inputs'], {'montant_promesse': parseInt(montantPromesse)})
                Object.assign(object['inputs'], {'montant_urbanisme': parseInt(montantUrbanisme)})
                Object.assign(object['inputs'], {'urbanisme': parseInt(urbanisme)})
                Object.assign(object['inputs'], {'promesse': parseInt(promesse)})
                Object.assign(object['inputs'], {'acte_en_main': parseInt(acteEnMain)})
                Object.assign(object['inputs'], {'montant_exigible': parseInt(montant_exigible)})
                Object.assign(object['inputs'], {'tva_concerne': parseFloat(tva_concerne)})
                object.dependances = dependances
                object.departementid = parseInt(departement)
            }
        }

        if (setter === "dependance") {
            if (dependances.length > 0)
                object.dependances = dependances

            if (value !== undefined) {

                let validator = false

                for (let i = 0; i < object["dependances"].length; i++) {
                    if (object['dependances'][i]?.simulateurid === value.simulateurid) {
                        object.dependances[i] = value
                        validator = true
                    }
                }

                if (validator === false) {
                    object.dependances.push(value)
                }

                setDependancesState(object.dependances)

                Object.assign(object['inputs'], {'montant_vente': parseInt(montantVente)})
                Object.assign(object['inputs'], {'montant_promesse': parseInt(montantPromesse)})
                Object.assign(object['inputs'], {'montant_urbanisme': parseInt(montantUrbanisme)})
                Object.assign(object['inputs'], {'urbanisme': parseInt(urbanisme)})
                Object.assign(object['inputs'], {'promesse': parseInt(promesse)})
                Object.assign(object['inputs'], {'acte_en_main': parseInt(acteEnMain)})
                Object.assign(object['inputs'], {'montant_exigible': parseInt(montant_exigible)})
                Object.assign(object['inputs'], {'tva_concerne': parseFloat(tva_concerne)})
                object.departementid = parseInt(departement)
            }
        }

        if (setter === setMontantVente) {
            Object.assign(object['inputs'], {'montant_vente': parseInt(value)})
            Object.assign(object['inputs'], {'montant_promesse': parseInt(montantPromesse)})
            Object.assign(object['inputs'], {'montant_urbanisme': parseInt(montantUrbanisme)})
            Object.assign(object['inputs'], {'urbanisme': parseInt(urbanisme)})
            Object.assign(object['inputs'], {'promesse': parseInt(promesse)})
            Object.assign(object['inputs'], {'acte_en_main': parseInt(acteEnMain)})
            Object.assign(object['inputs'], {'montant_exigible': parseInt(montant_exigible)})
            Object.assign(object['inputs'], {'tva_concerne': parseFloat(tva_concerne)})
            object.dependances = dependances
            object.departementid = parseInt(departement)
        }

        if (setter === setMontantPromesse) {
            Object.assign(object['inputs'], {'montant_promesse': parseInt(value)})
            Object.assign(object['inputs'], {'montant_vente': parseInt(montantVente)})
            Object.assign(object['inputs'], {'montant_urbanisme': parseInt(montantUrbanisme)})
            Object.assign(object['inputs'], {'urbanisme': parseInt(urbanisme)})
            Object.assign(object['inputs'], {'promesse': parseInt(promesse)})
            Object.assign(object['inputs'], {'acte_en_main': parseInt(acteEnMain)})
            Object.assign(object['inputs'], {'montant_exigible': parseInt(montant_exigible)})
            Object.assign(object['inputs'], {'tva_concerne': parseFloat(tva_concerne)})
            object.dependances = dependances
            object.departementid = parseInt(departement)
        }

        if (setter === setMontantUrbanisme) {
            Object.assign(object['inputs'], {'montant_urbanisme': parseInt(value)})
            Object.assign(object['inputs'], {'montant_promesse': parseInt(montantPromesse)})
            Object.assign(object['inputs'], {'montant_vente': parseInt(montantVente)})
            Object.assign(object['inputs'], {'urbanisme': parseInt(urbanisme)})
            Object.assign(object['inputs'], {'promesse': parseInt(promesse)})
            Object.assign(object['inputs'], {'acte_en_main': parseInt(acteEnMain)})
            Object.assign(object['inputs'], {'montant_exigible': parseInt(montant_exigible)})
            Object.assign(object['inputs'], {'tva_concerne': parseFloat(tva_concerne)})
            object.dependances = dependances
            object.departementid = parseInt(departement)
        }

        if (setter === setDepartement) {
            Object.assign(object['inputs'], {'montant_promesse': parseInt(montantPromesse)})
            Object.assign(object['inputs'], {'montant_vente': parseInt(montantVente)})
            Object.assign(object['inputs'], {'montant_urbanisme': parseInt(montantUrbanisme)})
            Object.assign(object['inputs'], {'urbanisme': parseInt(urbanisme)})
            Object.assign(object['inputs'], {'promesse': parseInt(promesse)})
            Object.assign(object['inputs'], {'acte_en_main': parseInt(acteEnMain)})
            Object.assign(object['inputs'], {'montant_exigible': parseInt(montant_exigible)})
            Object.assign(object['inputs'], {'tva_concerne': parseFloat(tva_concerne)})
            object.dependances = dependances
            object.departementid = parseInt(value)
        }


        if (setter === setUrbanisme) {
            Object.assign(object['inputs'], {'montant_promesse': parseInt(montantPromesse)})
            Object.assign(object['inputs'], {'montant_vente': parseInt(montantVente)})
            Object.assign(object['inputs'], {'montant_urbanisme': parseInt(montantUrbanisme)})
            Object.assign(object['inputs'], {'urbanisme': parseInt(value)})
            Object.assign(object['inputs'], {'promesse': parseInt(promesse)})
            Object.assign(object['inputs'], {'acte_en_main': parseInt(acteEnMain)})
            Object.assign(object['inputs'], {'montant_exigible': parseInt(montant_exigible)})
            Object.assign(object['inputs'], {'tva_concerne': parseFloat(tva_concerne)})
            object.dependances = dependances
            object.departementid = parseInt(departement)
        }

        if (setter === setPromesse) {
            Object.assign(object['inputs'], {'montant_promesse': parseInt(montantPromesse)})
            Object.assign(object['inputs'], {'montant_vente': parseInt(montantVente)})
            Object.assign(object['inputs'], {'montant_urbanisme': parseInt(montantUrbanisme)})
            Object.assign(object['inputs'], {'urbanisme': parseInt(urbanisme)})
            Object.assign(object['inputs'], {'promesse': parseInt(value)})
            Object.assign(object['inputs'], {'acte_en_main': parseInt(acteEnMain)})
            Object.assign(object['inputs'], {'montant_exigible': parseInt(montant_exigible)})
            Object.assign(object['inputs'], {'tva_concerne': parseFloat(tva_concerne)})
            object.dependances = dependances
            object.departementid = parseInt(departement)
        }

        if (setter === setActeEnMain) {
            Object.assign(object['inputs'], {'montant_vente': parseInt(montantVente)})
            Object.assign(object['inputs'], {'montant_promesse': parseInt(montantPromesse)})
            Object.assign(object['inputs'], {'montant_urbanisme': parseInt(montantUrbanisme)})
            Object.assign(object['inputs'], {'urbanisme': parseInt(urbanisme)})
            Object.assign(object['inputs'], {'promesse': parseInt(promesse)})
            Object.assign(object['inputs'], {'acte_en_main': parseInt(value)})
            Object.assign(object['inputs'], {'montant_exigible': parseInt(montant_exigible)})
            Object.assign(object['inputs'], {'tva_concerne': parseFloat(tva_concerne)})
            object.dependances = dependances
            object.departementid = parseInt(departement)
        }

        if (setter === setMontantExigible) {
            Object.assign(object['inputs'], {'montant_promesse': parseInt(montantPromesse)})
            Object.assign(object['inputs'], {'montant_vente': parseInt(montantVente)})
            Object.assign(object['inputs'], {'montant_urbanisme': parseInt(montantUrbanisme)})
            Object.assign(object['inputs'], {'urbanisme': parseInt(urbanisme)})
            Object.assign(object['inputs'], {'promesse': parseInt(promesse)})
            Object.assign(object['inputs'], {'acte_en_main': parseInt(acteEnMain)})
            Object.assign(object['inputs'], {'montant_exigible': parseInt(value)})
            Object.assign(object['inputs'], {'tva_concerne': parseFloat(tva_concerne)})
            object.dependances = dependances
            object.departementid = parseInt(departement)
        }

        if (setter === setTva) {
            Object.assign(object['inputs'], {'montant_promesse': parseInt(montantPromesse)})
            Object.assign(object['inputs'], {'montant_vente': parseInt(montantVente)})
            Object.assign(object['inputs'], {'montant_urbanisme': parseInt(montantUrbanisme)})
            Object.assign(object['inputs'], {'urbanisme': parseInt(urbanisme)})
            Object.assign(object['inputs'], {'promesse': parseInt(promesse)})
            Object.assign(object['inputs'], {'acte_en_main': parseInt(acteEnMain)})
            Object.assign(object['inputs'], {'montant_exigible': parseInt(montant_exigible)})
            Object.assign(object['inputs'], {'tva_concerne': parseFloat(value)})
            object.dependances = dependances
            object.departementid = parseInt(departement)
        }


        if (props.isParent) {
            dispatch(setSimulateurObject(object));
        } else {

        }
    }

    const tvaVefa = [
        {
            id: 0.2,
            name: "20%"
        },
        {
            id: 0.1,
            name: "10%"
        },
        {
            id: 0.085,
            name: "8,5% (DOM)"
        },
        {
            id: 0.055,
            name: "5,5%"
        },
        {
            id: 0.021,
            name: "2,1% (DOM)"
        },
        {
            id: 0,
            name: "0% (DOM)"
        }]

    const depsTaxeIleFrance = [75, 77, 78, 91, 92, 93, 94, 95]
    const depsZrr = [14, 38, 51, 71]

    return (
        <div style={styles.container}>
            <div style={styles.card}>

                <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id}/>

                <TextfieldRemarques handleChange={handleChangeRemarque}/>

                {props?.isParent === true && props.departements !== "" ?
                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            Département :
                        </div>

                        <SelectDepartement items={props.departements} nameField={"name"} defaultValue={currUserDep} optionValue={"id"}
                                      handleChange={handleChange} setter={setDepartement}/>
                    </div>
                    :
                    <></>
                }

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        La vente est-elle consentie "Acte en main" ?
                    </div>
                    <div>
                        <ButtonYesno handleChange={handleChange} setter={setActeEnMain} fieldUndefined={false}
                                     defaultValue={0}/>
                    </div>
                </div>

                <div style={styles.cardRow}>
                    {
                      acteEnMain == 0 ?  <div style={styles.cardElemVerticalyCenter}>
                      Quel est le montant de la vente  ?
                  </div>
                   :   <div style={styles.cardElemVerticalyCenter}>
                        Quel est le montant de la vente  "acte en main" ?
                    </div>
                    }

                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer le montant"}
                        setter={setMontantVente}
                    />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                    Quel est le montant de la partie exigible du prix de vente :
                    </div>

                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer le montant"}
                        setter={setMontantExigible}
                    />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                    Quel est le taux de TVA qui concerne l'opération ?
                    <ToolTipPerso title={"Le taux de TVA peut être soit réduit, soit intermédiaire, soit normal."}/>
                    </div>
                    <SelectCommon items={tvaVefa} defaultValue={0.2} nameField={"name"} optionValue={"id"}
                                  handleChange={handleChange} setter={setTva}/>
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Y a-t-il un urbanisme demandé à un tiers, ou d'autres débours à prévoir ?
                        <ToolTipPerso title={"Les débours sur les ventes comprennent une somme de 120 euros représentant le coût moyen d'obtention d'un certificat d'urbanisme complet. Pour retirer ce coût, répondre 'non' à la question 'urbanisme demandé à un tiers'."}/>
                    </div>
                    <div>
                        <ButtonYesno handleChange={handleChange} setter={setUrbanisme} fieldUndefined={true}
                                     defaultValue={2}
                        />
                    </div>
                </div>

                {
                    parseInt(urbanisme) === 1 ?
                        <div style={styles.cardRow}>
                            <div style={styles.cardElemVerticalyCenter}>
                                Montant de l'urbanisme :
                            </div>
                            <TextfieldNumber
                                handleChange={handleChange}
                                type={"number"}
                                placeholder={"Indiquer le montant"}
                                setter={setMontantUrbanisme}
                            />
                        </div>
                        :
                        <></>
                }


                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Y'a-t-il un prêt hypothécaire ?
                        <ToolTipPerso title={"Accessoires compris et évalués au bordereau à concurrence de 20% du capital emprunté."}/>
                    </div>
                    <div>
                        <ButtonYesno handleChange={handleChange} setter={setPretHypo} fieldUndefined={false}
                                     defaultValue={0}/>
                    </div>
                </div>

                {
                    parseInt(pretHypo) === 1 ?
                        <>
                            <Simulateur_A1_12VEFA
                                // simulateur={props.simulateur}
                                // isParent={false}
                                // departements={departements}
                                contentDep={setDep1}
                                simulateur={props.simulateur}
                                isParent={false}
                                departementId={departement}
                                dependances={setSimu85}
                            />

                        </>
                        :
                        <></>
                }
            </div>

        </div>
    )
}

export default Simulateur_A2_6;
