import React, { useEffect, useState } from "react";
import { setSimulateurObject } from "../../../actions/simulateur.action";
import { useDispatch } from 'react-redux';
import { styles } from "../../../stylesheets/simulateurs.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import TextfieldNumberSPF from "../../../components/textfields/textfield.number.spf";
import SelectDepartement from "../../../components/selects/select.departement";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";

// Constitution de société́ avec apport immobilier à titre pur et simple et à titre onéreux
// Id simulateur 82

function Simulateur_82(props, simulateur, isParent, departements) {
    const dispatch = useDispatch();

    let currUser = JSON.parse(localStorage.getItem("user"));
    let currUserDep = 0
    if (currUser.societe_zip.startsWith("97")) {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 3);
        currUserDep = parseInt(trimedZip)
    } else {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 2);
        currUserDep = parseInt(trimedZip)
    }

    const [departement, setDepartement] = useState(0)
    const [montant_honoraires, setHonoraire] = useState(0)
    const [valeur_biens_apportes, setBiens] = useState(0)
    const [apport_pur_simple, setApportPS] = useState(0)
    const [apport_onereux, setOnereux] = useState(0)
    const [nombre_spf, setNbSPF] = useState(0)
    const [valeursSPF, setValeursSPF] = useState([])

    const handleChange = async (setter, value) => {
        if (setter === setDepartement) {
            setDepartement(parseInt(value))
        } else setter(value);
        setInRedux(setter, value)
    };

    const handleChangeRemarque = async (value) => {
        localStorage.setItem("remarque", value)
    }
    useEffect(async () => {
        if (nombre_spf > 1) {
            if (valeursSPF.length < nombre_spf) {
                let handle = []
                for (let i = 0; i < (valeursSPF.length); i++) {
                    handle[i] = valeursSPF[i]
                }
                for (let i = valeursSPF.length; i < (nombre_spf); i++) {
                    handle[i] = {}
                }
                await handleChange(setValeursSPF, handle)
            } else if (nombre_spf < valeursSPF.length) {
                //console.log("SPLICE CASE")
                valeursSPF.splice(parseInt(nombre_spf))
                await handleChange(setValeursSPF, valeursSPF)
            } else if (valeursSPF.length === 0) {
                await handleChange(setValeursSPF, [{}, {}])
            }
        } else {
            await handleChange(setValeursSPF, [])
        }
    }, [nombre_spf]);

    function setInRedux(setter, value) {
        if (props.isParent) {

            let object = {
                "simulateurid": props.simulateur.id,
                "departementid": 0,
                "inputs": {},
                "dependances": null
            };

            if (setter === setDepartement) {
                Object.assign(object['inputs'], { 'montant_honoraires': parseInt(montant_honoraires) })
                Object.assign(object['inputs'], { 'valeur_biens_apportes': parseInt(valeur_biens_apportes) })
                Object.assign(object['inputs'], { 'apport_pur_simple': parseInt(apport_pur_simple) })
                Object.assign(object['inputs'], { 'apport_onereux': parseInt(apport_onereux) })
                Object.assign(object['inputs'], { 'nombre_spf': parseInt(nombre_spf) })
                object.dependances = valeursSPF
                object.departementid = parseInt(value)
            }

            if (setter === setHonoraire) {
                Object.assign(object['inputs'], { 'montant_honoraires': parseInt(value) })
                Object.assign(object['inputs'], { 'valeur_biens_apportes': parseInt(valeur_biens_apportes) })
                Object.assign(object['inputs'], { 'apport_pur_simple': parseInt(apport_pur_simple) })
                Object.assign(object['inputs'], { 'apport_onereux': parseInt(apport_onereux) })
                Object.assign(object['inputs'], { 'nombre_spf': parseInt(nombre_spf) })
                object.dependances = valeursSPF
                object.departementid = parseInt(departement)
            }

            if (setter === setBiens) {
                Object.assign(object['inputs'], { 'montant_honoraires': parseInt(montant_honoraires) })
                Object.assign(object['inputs'], { 'valeur_biens_apportes': parseInt(value) })
                Object.assign(object['inputs'], { 'apport_pur_simple': parseInt(apport_pur_simple) })
                Object.assign(object['inputs'], { 'apport_onereux': parseInt(apport_onereux) })
                Object.assign(object['inputs'], { 'nombre_spf': parseInt(nombre_spf) })
                object.dependances = valeursSPF
                object.departementid = parseInt(departement)
            }

            if (setter === setApportPS) {
                Object.assign(object['inputs'], { 'montant_honoraires': parseInt(montant_honoraires) })
                Object.assign(object['inputs'], { 'valeur_biens_apportes': parseInt(value) })
                Object.assign(object['inputs'], { 'apport_pur_simple': parseInt(value) })
                Object.assign(object['inputs'], { 'apport_onereux': parseInt(apport_onereux) })
                Object.assign(object['inputs'], { 'nombre_spf': parseInt(nombre_spf) })
                object.dependances = valeursSPF
                object.departementid = parseInt(departement)
            }

            if (setter === setOnereux) {
                Object.assign(object['inputs'], { 'montant_honoraires': parseInt(montant_honoraires) })
                Object.assign(object['inputs'], { 'valeur_biens_apportes': parseInt(value) })
                Object.assign(object['inputs'], { 'apport_pur_simple': parseInt(apport_pur_simple) })
                Object.assign(object['inputs'], { 'apport_onereux': parseInt(value) })
                Object.assign(object['inputs'], { 'nombre_spf': parseInt(nombre_spf) })
                object.dependances = valeursSPF
                object.departementid = parseInt(departement)
            }

            if (setter === setNbSPF) {
                Object.assign(object['inputs'], { 'montant_honoraires': parseInt(montant_honoraires) })
                Object.assign(object['inputs'], { 'valeur_biens_apportes': parseInt(valeur_biens_apportes) })
                Object.assign(object['inputs'], { 'apport_pur_simple': parseInt(apport_pur_simple) })
                Object.assign(object['inputs'], { 'apport_onereux': parseInt(apport_onereux) })
                Object.assign(object['inputs'], { 'nombre_spf': parseInt(value) })
                object.dependances = valeursSPF
                object.departementid = parseInt(departement)
            }

            if (setter === setValeursSPF) {
                Object.assign(object['inputs'], { 'montant_honoraires': parseInt(montant_honoraires) })
                Object.assign(object['inputs'], { 'valeur_biens_apportes': parseInt(valeur_biens_apportes) })
                Object.assign(object['inputs'], { 'apport_pur_simple': parseInt(apport_pur_simple) })
                Object.assign(object['inputs'], { 'apport_onereux': parseInt(apport_onereux) })
                Object.assign(object['inputs'], { 'nombre_spf': parseInt(nombre_spf) })
                object.dependances = value
                object.departementid = parseInt(departement)
            }
            //console.log(JSON.stringify(object))
            dispatch(setSimulateurObject(object));

        } else {

            // process child simu

        }
    }

    function functionSetValeursSPF(index, value) {
        //console.log("index : " + index)
        //console.log("value : " + value)
        if (valeursSPF.length >= 2) {
            let formatedData = {
                valeur_spf: parseInt(value)
            }
            valeursSPF[index] = formatedData
            setInRedux(setValeursSPF, valeursSPF)
        }
    }

    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

                <TextfieldRemarques handleChange={handleChangeRemarque}/>

                <p style={styles.cardSubTitle}>
                    (Acte tarifé au titre du Tableau 5, n° 159). En plus de l’honoraire relatif à la constitution de la
                    société, des émoluments (acte et formalités) sont à prévoir sur les biens immobiliers apportés.
                </p>

                {props?.isParent === true && props.departements !== "" ?
                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            Département :
                        </div>

                        <SelectDepartement items={props.departements} nameField={"name"} defaultValue={currUserDep}
                            optionValue={"id"}
                            handleChange={handleChange} setter={setDepartement} />
                    </div>
                    :
                    <></>
                }

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Quel est le montant de l’honoraire HT ?
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer le montant"}
                        setter={setHonoraire}
                    />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Quelle est la valeur totale des biens immobiliers apportés ?
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer la valeur"}
                        setter={setBiens}
                    />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Dont apport à titre pur et simple :
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer la valeur"}
                        setter={setApportPS}
                    />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Dont apport à titre onéreux :
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer la valeur"}
                        setter={setOnereux}
                    />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Quel est le nombre de SPF concernés par l’apport immobilier ?
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer le nombre"}
                        setter={setNbSPF}
                    />
                </div>

                {
                    valeursSPF.length >= 2 ?
                        valeursSPF.map((valeur, index) => {
                            return (
                                <div style={styles.cardRowChild}>
                                    <div style={styles.cardRow}>
                                        <div style={styles.cardElemVerticalyCenter}>
                                            Quelle est la valeur du bien apporté au SPF n° {index + 1} ?
                                        </div>
                                        <TextfieldNumberSPF
                                            index={index}
                                            type={"number"}
                                            placeholder={"Indiquer la valeur"}
                                            functionSetValeurs={functionSetValeursSPF}
                                        />
                                    </div>
                                </div>
                            )
                        }) :
                        <></>
                }


            </div>

        </div>
    )
}

export default Simulateur_82;
