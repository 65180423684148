import React, { useEffect, useState } from "react";
import Colors from "../../useclass/colors";
import { styles } from "../../stylesheets/simulateurs.css";
import Footer from "../../layouts/footer/footer";
import InfosFooter from "../../layouts/infosfooter/infosfooter";

function Infos(props) {
  const styles = {
    container: {
      padding: 0,
      margin: 0,
      width: "100vw",
      minHeight: "auto",
    },
    card: {
      backgroundColor: Colors.white,
      boxShadow: "2px 2px 1px rgba(0,0,0, 0.2)",
      borderRadius: 5,
      width: "90%",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: 200,
      paddingBottom: 50,
      paddingLeft: 50,
      paddingRight: 50,
      minHeight: "30vh",
    },
    cardTitle: {
      fontWeight: "normal",
      color: "#275d83",
      textAlign: "center",
      lineHeight: "1.4em",
      paddingTop: 20,
      marginTop: 5,
      marginBottom: 60,
    },
    cardSubTitle: {
      fontSize: 18,
      marginLeft: 35,
    },
    footer: {
      left: 0,
      bottom: 0,
    },
  };

  return (
    <>
      <div style={styles.container}>
        <div style={styles.card}>
          <h1 style={styles.cardTitle}>Informations sur les barèmes</h1>
          <div>
            <p>
              Cet outil doit être utilisé à titre d’indication immédiate des
              frais dus aux notaires.
              <br />
              Les chiffres donnés sont des approximations et les simulations
              sont possibles jusqu’à 200 000 000 €. Au-delà, merci de nous
              contacter.
            </p>
            <p>
              <span style={styles.cardSubTitle}>
                <b>Provision</b>
              </span>
              <br />
              correspond au total des colonnes "Emoluments" + "Trésor" + "Droit
              TVA" + "Débours".
            </p>
            <p>
              <span style={styles.cardSubTitle}>
                <b>Pourcentage </b>
              </span>
              <br />
              correspond au % relatif des frais par rapport au prix.
            </p>
            <p>
              <span style={styles.cardSubTitle}>
                <b>Emoluments</b>
              </span>
              <br />
              (honoraires) comprennent selon les rubriques les émoluments de
              formalités H.T ainsi que les émoluments d’actes H.T.
            </p>
            <p>
              <span style={styles.cardSubTitle}>
                <b>Trésor </b>
              </span>
              <br />
              Comprend selon les rubriques - Les droits d’enregistrement ou la
              Taxe de Publicité Foncière (TPF) - Les prélèvements pour frais
              d’assiette - La TVA sur les émoluments et honoraires - La
              Contribution à la Sécurité Immobilière (ancien salaire du
              Conservateur) - Coût états hypothécaires
            </p>
            <p>
              <span style={styles.cardSubTitle}>
                <b>Débours </b>
              </span>
              <br />
              Comprennent selon les rubriques les postes suivants : Le salaire
              du greffier - Publicité - BODACC - Coût certificat d’urbanisme et
              autres.
            </p>
          </div>
        </div>
      </div>
      <div style={styles.footer}>
        <InfosFooter />
        <Footer />
      </div>
    </>
  );
}

export default Infos;
