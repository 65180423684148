import { Document, Font, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import * as React from "react";

import fontRobotoRegular from "../../assets/fonts/Roboto/Roboto-Regular.ttf"
import fontRobotoLight from "../../assets/fonts/Roboto/Roboto-Light.ttf"
import fontRobotoBold from "../../assets/fonts/Roboto/Roboto-Bold.ttf"

Font.register({ family: "RobotoRegular", src: fontRobotoRegular });
Font.register({ family: "RobotoLight", src: fontRobotoLight });
Font.register({ family: "RobotoBold", src: fontRobotoBold });

let newDate = new Date()
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let day = newDate.getDate();

if (month < 10) {
    month = "0" + month;
}

if (day < 10) {
    day = "0" + day;
}
const currentDate = `${day}/${month}/${year}`

const styles = StyleSheet.create({
    fontRegular: { fontFamily: "RobotoRegular" },
    fontLight: { fontFamily: "RobotoLight" },
    fontBold: { fontFamily: "RobotoBold" },
    page: {
        paddingTop: 20,
        paddingBottom: 50,
    },
    headerRight: {
        marginLeft: "50%",
        marginRight: 40,
        fontSize: 12,
        textAlign: "right",
        lineHeight: 1.5,
        wordBreak: "keep-all"
    },
    headerName: {
        fontSize: 11,
        fontFamily: "RobotoBold",
        wordBreak: "keep-all"
    },
    headerAdress: {
        fontSize: 10,
        fontFamily: "RobotoRegular"
    },
    headerDate: {
        marginTop: 20,
        fontSize: 9.5,
        fontFamily: "RobotoRegular"
    },
    remarque: {
        border: "1px solid grey",
        borderRadius: 3,
        width: "80%",
        marginLeft: "10%",
        fontFamily: "RobotoRegular",
        fontSize: 10,
        marginTop: 25,
        lineHeight: 2,
    },
    remarqueText: {
        marginTop: 8,
        marginLeft: 5,
    },
    resultsTitle: {
        fontSize: 14,
        paddingLeft: "10%",
        width: "100%",
        marginBottom: 10,
        marginTop: 15,
    },
    donataireBlock: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        marginTop: "5px",
        marginBottom: "15px",
        border: "1px solid #71ADD9",
        borderRadius: 3,
        width: "80%",
        marginLeft: "10%",
        fontFamily: "RobotoBold",
        height: "auto",
        minHeight: "150px",
    },
    heritierBlock: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        marginTop: 5,
        marginBottom: 15,
        border: "1px solid #71ADD9",
        borderRadius: 3,
        width: "80%",
        marginLeft: "10%",
        fontFamily: "RobotoBold",
        height: "auto",
    },
    donataireTitle: {
        height: "100%",
        width: "auto",
        fontSize: 13,
        color: "white",
        backgroundColor: "#71ADD9",
        textAlign: "center",
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 40,
    },
    heritierTitle: {
        height: "100%",
        width: "auto",
        fontSize: 13,
        color: "white",
        backgroundColor: "#71ADD9",
        textAlign: "center",
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 20,
    },
    donateurTitle: {
        width: "100%",
        textAlign: "center",
        color: "white",
        backgroundColor: "rgb(157 114 169)",
        fontSize: 12,
        padding: 10,
    },
    donateurBlock: {
        flex: 1,
        border: "1px solid rgb(157 114 169)",
        borderRadius: 3,
        margin: 10,
        maxWidth: "45%",
    },
    heritierBlock2: {
        flex: 1,
        margin: 10,
        maxWidth: "100%",
    },
    donateurResults: {
        paddingLeft: 10,
        paddingRight: 10,
    },
    dmtgResults: {
        fontWeight: 'normal',
        fontSize: 10,
        paddingTop: 5,
        paddingBottom: 5,
    },
    bodyFooterCtn: {
        width: "80%",
        marginLeft: "10%",
        position: "absolute",
        bottom: 15,
        left: 0,
        right: 0
    },
    bodyFooterBold: {
        fontFamily: "RobotoBold",
        fontSize: 8,
        marginTop: 10,
        textAlign: "justify",
    },
    bodyFooterLight: {
        fontFamily: "RobotoLight",
        fontSize: 6,
        marginTop: 5,
    },
    bodyFooterMapped: {
        fontSize: 8,
        marginTop: 4,
    },
    bodyFooterMappedTitle: {
        fontSize: 8,
        marginTop: 4,
        fontFamily: "RobotoBold",
    },
    rappel: {
        width: "80%",
        marginLeft: "10%",
        // marginTop: 175,
        marginTop: 30,
        marginBottom: 50,
    },
    containerTitleSimu: {
        position: "relative",
        marginTop: 20,
        marginRight: "auto",
        marginLeft: "auto",
        width: "75%",
    },
    titleSimu: {
        fontSize: 14,
        textAlign: "center",
        fontFamily: "RobotoBold",
        wordBreak: "keep-all",
    },

    rappelBlockDonataire: {
        marginTop: 5,
        marginBottom: 10,
    },

    rappelDonateurTitle: {
        fontSize: 10,
        marginTop: 5,
        fontWeight: "bold",
        color: "rgb(157 114 169)",
    },

    rappelDonataireTitle: {
        fontSize: 9,
        fontWeight: "bold",
        color: "#71ADD9",
    },
    resultsBlock: {
        marginTop: 5,
        marginBottom: 10,
    },
});


const GenerateDMTGPdfFile: React.FC<DocumentProps> = ({ simulateurObject }) => {
    let remarque = localStorage.getItem("remarque");

    let currUser = JSON.parse(localStorage.getItem("user"));

    let requestSimu = JSON.parse(localStorage.getItem("dmtgInputs"))

    let nameSimu = localStorage.getItem("nameSimuDMTG")

    let result = simulateurObject

    return (
        // <PDFViewer style={{width: "98vw", height: "98vh"}}>
        <Document>
            <Page size="A4" style={styles.page}>

                <View style={styles.headerRight}>
                    <Text style={styles.headerName}>
                        {currUser.societe}
                    </Text>
                    <Text style={styles.headerAdress}>
                        {currUser.societe_address}
                    </Text>

                    <Text style={styles.headerAdress}>
                        {currUser.societe_zip + " " + currUser.societe_town}
                    </Text>

                    <Text style={styles.headerDate}>
                        Dossier suivi par : {currUser.firstname + " " + currUser.lastname}
                    </Text>

                    <View style={styles.headerDate}>
                        <Text>
                            Le : {currentDate}
                        </Text>
                    </View>
                </View>
                <View style={styles.containerTitleSimu}>
                    <Text style={styles.titleSimu}>
                        {nameSimu}
                    </Text>
                </View>
                <View style={styles.remarque}>
                    <Text style={styles.remarqueText}>
                        Remarques : {remarque}
                    </Text>
                </View>

                <View>
                    <Text style={styles.resultsTitle}>
                        Résultat de votre calcul de DMTG :
                    </Text>

                    <View style={styles.resultsBlock}>
                        {
                            result?.map((array, donataire) => {
                                if (requestSimu.data[0].donataires[0].simulateurid === 55) {
                                    return (<>
                                        <View style={styles.heritierBlock} wrap={false}>
                                            <Text style={styles.heritierTitle}>Héritier {donataire + 1}</Text>
                                            {
                                                array?.map((object, donateur) => {
                                                    return (<>
                                                        <View style={styles.heritierBlock2} wrap={false}>
                                                            <View style={styles.donateurResults}>
                                                                {
                                                                    object?.map((line, index) => {
                                                                        if (result[donataire][donateur][index]?.key === "DMTG") {
                                                                            return (<Text style={styles.dmtgResults}>
                                                                                Droits DMTG à payer : {(result[donataire][donateur][index]?.value)}
                                                                            </Text>)
                                                                        } else if (result[donataire][donateur][index]?.key === "base_dmtg") {
                                                                            return (<Text style={styles.dmtgResults}>
                                                                                Base Taxable : {(result[donataire][donateur][index]?.value)}
                                                                            </Text>)
                                                                        } else if (result[donataire][donateur][index]?.key === "immo") {
                                                                            return (<Text style={styles.dmtgResults}>
                                                                                Montant Immobilier : {(result[donataire][donateur][index]?.value)}
                                                                            </Text>)
                                                                        } else if (result[donataire][donateur][index]?.key === "especes") {
                                                                            return (<Text style={styles.dmtgResults}>
                                                                                Montant Espèces : {(result[donataire][donateur][index]?.value)}
                                                                            </Text>)
                                                                        }
                                                                        else if (result[donataire][donateur][index]?.key === "abattement_restant") {
                                                                            return (<Text style={styles.dmtgResults}>
                                                                                Abattement legal restant : {(result[donataire][donateur][index]?.value)}
                                                                            </Text>)
                                                                        }

                                                                    })
                                                                }
                                                            </View>
                                                        </View>
                                                    </>)
                                                })
                                            }</View>

                                    </>)
                                } else {
                                    return (<>
                                        <View style={styles.donataireBlock} wrap={false}>
                                            <Text style={styles.donataireTitle}>Donataire {donataire + 1}</Text>
                                            {
                                                array?.map((object, donateur) => {
                                                    return (<>
                                                        <View style={styles.donateurBlock} wrap={false}>
                                                            <Text
                                                                style={styles.donateurTitle}>Donateur {donateur + 1}</Text>
                                                            <View style={styles.donateurResults}>
                                                                {
                                                                    object?.map((line, index) => {
                                                                        if (result[donataire][donateur][index]?.key === "DMTG") {
                                                                            return (<Text style={styles.dmtgResults}>
                                                                                Droits DMTG à payer : {(result[donataire][donateur][index]?.value)}
                                                                            </Text>)
                                                                        } else if (result[donataire][donateur][index]?.key === "base_dmtg") {
                                                                            return (<Text style={styles.dmtgResults}>
                                                                                Base Taxable : {(result[donataire][donateur][index]?.value)}
                                                                            </Text>)
                                                                        } else if (result[donataire][donateur][index]?.key === "immo") {
                                                                            return (<Text style={styles.dmtgResults}>
                                                                                Montant Immobilier : {(result[donataire][donateur][index]?.value)}
                                                                            </Text>)
                                                                        } else if (result[donataire][donateur][index]?.key === "especes") {
                                                                            return (<Text style={styles.dmtgResults}>
                                                                                Montant Espèces : {(result[donataire][donateur][index]?.value)}
                                                                            </Text>)
                                                                        } else if (result[donataire][donateur][index]?.key === "abattement_restant") {
                                                                            return (<Text style={styles.dmtgResults}>
                                                                                Abattement legal restant : {(result[donataire][donateur][index]?.value)}
                                                                            </Text>)
                                                                        }

                                                                    })
                                                                }
                                                            </View>
                                                        </View>
                                                    </>)
                                                })
                                            }</View>

                                    </>)
                                }
                            })

                        }
                    </View>
                </View>

                {/* MAP FOR INPUTS*/}

                <View style={styles.rappel}>

                    <Text style={styles.bodyFooterMappedTitle}>
                        Rappel de votre simulation :
                    </Text>

                    {
                        requestSimu?.data?.map((array, donateur) => {
                            if (requestSimu.data[0].donataires[0].simulateurid === 55) {
                                return (<>
                                    {requestSimu?.data[donateur]?.donataires?.map((inputs, donataire) => {
                                        return (<>
                                            <View style={styles.rappelBlockDonataire} wrap={false}>
                                                <Text
                                                    style={styles.rappelDonataireTitle}>Héritier {donataire + 1}</Text>
                                                {Object.keys(requestSimu?.data[donateur]?.donataires[donataire]?.inputs)?.map((key) => {
                                                    if (key === "lien_parente_donation") {
                                                        if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key] === 1) {
                                                            return (<Text style={styles.bodyFooterMapped}>
                                                                Lien de parenté : Entre époux ou partenaires pacsés
                                                            </Text>)
                                                        } else if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key] === 2) {
                                                            return (<Text style={styles.bodyFooterMapped}>
                                                                Lien de parenté : En ligne directe - Ascendants ou
                                                                enfants
                                                            </Text>)
                                                        } else if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key] === 3) {
                                                            return (<Text style={styles.bodyFooterMapped}>
                                                                Lien de parenté : Entre frères et sœurs
                                                            </Text>)
                                                        } else if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key] === 4) {
                                                            return (<Text style={styles.bodyFooterMapped}>
                                                                Lien de parenté : Aux neveux et nièces
                                                            </Text>)
                                                        } else if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key] === 5) {
                                                            return (<Text style={styles.bodyFooterMapped}>
                                                                Lien de parenté : Aux petits-enfants
                                                            </Text>)
                                                        } else if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key] === 6) {
                                                            return (<Text style={styles.bodyFooterMapped}>
                                                                Lien de parenté : Aux arrières petits-enfants
                                                            </Text>)
                                                        } else if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key] === 7) {
                                                            return (<Text style={styles.bodyFooterMapped}>
                                                                Lien de parenté : Entre parents au-dela du 4ème degré et
                                                                non parents
                                                            </Text>)
                                                        }
                                                    } else if (key === "lien_parente_succession") {
                                                        if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key] === 1) {
                                                            return (<Text style={styles.bodyFooterMapped}>
                                                                Lien de parenté : En ligne directe - Ascendants ou
                                                                enfants
                                                            </Text>)
                                                        } else if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key] === 2) {
                                                            if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs["conditions_particulieres"] === 1) {
                                                                return (<Text style={styles.bodyFooterMapped}>
                                                                    Lien de parenté : Entre frères et sœurs
                                                                    Conditions particulières : Oui
                                                                </Text>)
                                                            } else if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs["conditions_particulieres"] === 0) {
                                                                return (<Text style={styles.bodyFooterMapped}>
                                                                    Lien de parenté : Entre frères et sœurs
                                                                    Conditions particulières : Non
                                                                </Text>)
                                                            }
                                                        } else if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key] === 3) {
                                                            if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs["propre_chef"] === 1) {
                                                                return (<Text style={styles.bodyFooterMapped}>
                                                                    Lien de parenté : Aux neveux et nièces
                                                                    De leur propre-chef : Oui
                                                                </Text>)
                                                            } else if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs["propre_chef"] === 0) {
                                                                return (<Text style={styles.bodyFooterMapped}>
                                                                    Lien de parenté : Aux neveux et nièces
                                                                    De leur propre-chef : Non
                                                                </Text>)
                                                            }
                                                        } else if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key] === 4) {
                                                            return (<Text style={styles.bodyFooterMapped}>
                                                                Lien de parenté : Entre collatéraux jusqu'au 4ème degré
                                                                inclusivement
                                                            </Text>)
                                                        } else if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key] === 5) {
                                                            return (<Text style={styles.bodyFooterMapped}>
                                                                Lien de parenté : Entre parents au-delà du 4ème degré et
                                                                non parents
                                                            </Text>)
                                                        }
                                                    } else if (key === "handicap") {
                                                        if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key] === 1) {
                                                            return (<Text style={styles.bodyFooterMapped}>
                                                                Bénéficiaire en situation de handicap : Oui
                                                            </Text>)
                                                        } else if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key] === 0) {
                                                            return (<Text style={styles.bodyFooterMapped}>
                                                                Bénéficiaire en situation de handicap : Non
                                                            </Text>)
                                                        }
                                                    } else if (key === "montant_especes") {
                                                        return (<Text style={styles.bodyFooterMapped}>
                                                            Montant Espèces
                                                            : {requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key].toString() + " €"}
                                                        </Text>)
                                                    } else if (key === "montant_immo") {
                                                        return (<Text style={styles.bodyFooterMapped}>
                                                            Montant Immobilier
                                                            : {requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key].toString() + " €"}
                                                        </Text>)
                                                    } else if (key === "montant_donation") {
                                                        return (<Text style={styles.bodyFooterMapped}>
                                                            Montant de la donation
                                                            : {requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key].toString() + " €"}
                                                        </Text>)
                                                    } else if (key === "montant_succession") {
                                                        return (<Text style={styles.bodyFooterMapped}>
                                                            Montant de la succession
                                                            : {requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key].toString() + " €"}
                                                        </Text>)
                                                    } else if (key === "montant_utilise") {
                                                        return (<Text style={styles.bodyFooterMapped}>
                                                            Abattement legal déjà utilisé
                                                            : {requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key].toString() + " €"}
                                                        </Text>)
                                                    }
                                                }
                                                )
                                                }
                                            </View>
                                        </>)
                                    })}
                                </>)
                            } else {
                                return (<>
                                    <Text style={styles.rappelDonateurTitle} wrap={false}>Donateur {donateur + 1}</Text>
                                    {requestSimu?.data[donateur]?.donataires?.map((inputs, donataire) => {
                                        return (<>
                                            <View style={styles.rappelBlockDonataire} wrap={false}>
                                                <Text
                                                    style={styles.rappelDonataireTitle}>Donataire {donataire + 1}</Text>
                                                {Object.keys(requestSimu?.data[donateur]?.donataires[donataire]?.inputs)?.map((key) => {
                                                    if (key === "lien_parente_donation") {
                                                        if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key] === 1) {
                                                            return (<Text style={styles.bodyFooterMapped}>
                                                                Lien de parenté : Entre époux ou partenaires pacsés
                                                            </Text>)
                                                        } else if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key] === 2) {
                                                            return (<Text style={styles.bodyFooterMapped}>
                                                                Lien de parenté : En ligne directe - Ascendants ou
                                                                enfants
                                                            </Text>)
                                                        } else if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key] === 3) {
                                                            return (<Text style={styles.bodyFooterMapped}>
                                                                Lien de parenté : Entre frères et sœurs
                                                            </Text>)
                                                        } else if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key] === 4) {
                                                            return (<Text style={styles.bodyFooterMapped}>
                                                                Lien de parenté : Aux neveux et nièces
                                                            </Text>)
                                                        } else if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key] === 5) {
                                                            return (<Text style={styles.bodyFooterMapped}>
                                                                Lien de parenté : Aux petits-enfants
                                                            </Text>)
                                                        } else if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key] === 6) {
                                                            return (<Text style={styles.bodyFooterMapped}>
                                                                Lien de parenté : Aux arrières petits-enfants
                                                            </Text>)
                                                        } else if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key] === 7) {
                                                            return (<Text style={styles.bodyFooterMapped}>
                                                                Lien de parenté : Entre parents au-dela du 4ème degré et
                                                                non parents
                                                            </Text>)
                                                        }
                                                    } else if (key === "lien_parente_succession") {
                                                        if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key] === 1) {
                                                            return (<Text style={styles.bodyFooterMapped}>
                                                                Lien de parenté : En ligne directe - Ascendants ou
                                                                enfants
                                                            </Text>)
                                                        } else if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key] === 2) {
                                                            if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs["conditions_particulieres"] === 1) {
                                                                return (<Text style={styles.bodyFooterMapped}>
                                                                    Lien de parenté : Entre frères et sœurs
                                                                    Conditions particulières : Oui
                                                                </Text>)
                                                            } else if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs["conditions_particulieres"] === 0) {
                                                                return (<Text style={styles.bodyFooterMapped}>
                                                                    Lien de parenté : Entre frères et sœurs
                                                                    Conditions particulières : Non
                                                                </Text>)
                                                            }
                                                        } else if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key] === 3) {
                                                            if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs["propre_chef"] === 1) {
                                                                return (<Text style={styles.bodyFooterMapped}>
                                                                    Lien de parenté : Aux neveux et nièces
                                                                    De leur propre-chef : Oui
                                                                </Text>)
                                                            } else if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs["propre_chef"] === 0) {
                                                                return (<Text style={styles.bodyFooterMapped}>
                                                                    Lien de parenté : Aux neveux et nièces
                                                                    De leur propre-chef : Non
                                                                </Text>)
                                                            }
                                                        } else if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key] === 4) {
                                                            return (<Text style={styles.bodyFooterMapped}>
                                                                Lien de parenté : Entre collatéraux jusqu'au 4ème degré
                                                                inclusivement
                                                            </Text>)
                                                        } else if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key] === 5) {
                                                            return (<Text style={styles.bodyFooterMapped}>
                                                                Lien de parenté : Entre parents au-delà du 4ème degré et
                                                                non parents
                                                            </Text>)
                                                        }
                                                    } else if (key === "tepa") {
                                                        if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key] === 1) {
                                                            return (<>
                                                                <Text style={styles.bodyFooterMapped}>
                                                                    Abattement spécifique loi TEPA : Oui
                                                                </Text>
                                                                <Text style={styles.bodyFooterMapped}>
                                                                    Abattement TEPA déjà utilisé
                                                                    : {requestSimu?.data[donateur]?.donataires[donataire]?.inputs["montant_tepa_utilise"].toString() + " €"}
                                                                </Text>
                                                            </>)
                                                        } else if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key] === 0) {
                                                            return (<Text style={styles.bodyFooterMapped}>
                                                                Abattement spécifique loi TEPA : Non
                                                            </Text>)
                                                        }
                                                    } else if (key === "montant_utilise") {
                                                        return (<Text style={styles.bodyFooterMapped}>
                                                            Abattement legal déjà utilisé
                                                            : {requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key].toString() + " €"}
                                                        </Text>)
                                                    } else if (key === "handicap") {
                                                        if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key] === 1) {
                                                            return (<Text style={styles.bodyFooterMapped}>
                                                                Bénéficiaire en situation de handicap : Oui
                                                            </Text>)
                                                        } else if (requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key] === 0) {
                                                            return (<Text style={styles.bodyFooterMapped}>
                                                                Bénéficiaire en situation de handicap : Non
                                                            </Text>)
                                                        }
                                                    } else if (key === "montant_especes") {
                                                        return (<Text style={styles.bodyFooterMapped}>
                                                            Montant espèces
                                                            : {requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key].toString() + " €"}
                                                        </Text>)
                                                    } else if (key === "montant_immo") {
                                                        return (<Text style={styles.bodyFooterMapped}>
                                                            Montant immobilier
                                                            : {requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key].toString() + " €"}
                                                        </Text>)
                                                    } else if (key === "montant_donation") {
                                                        return (<Text style={styles.bodyFooterMapped}>
                                                            Montant de la donation
                                                            : {requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key].toString() + " €"}
                                                        </Text>)
                                                    } else if (key === "montant_succession") {
                                                        return (<Text style={styles.bodyFooterMapped}>
                                                            Montant de la succession lui revenant
                                                            : {requestSimu?.data[donateur]?.donataires[donataire]?.inputs[key].toString() + " €"}
                                                        </Text>)
                                                    }
                                                }
                                                )
                                                }
                                            </View>
                                        </>)
                                    })}
                                </>)
                            }
                        })
                    }

                </View>


                <View style={styles.bodyFooterCtn} fixed>
                    <Text style={styles.bodyFooterBold}>
                        IMPORTANT : Les informations et les résultats présentés ci-dessus sont
                        donnés à titre
                        indicatif et ne peuvent en aucun cas constituer un
                        document à caractère contractuel. Les frais indiqués doivent être adressés
                        par virement
                        au minimum 48h-72h à l'avance.
                    </Text>
                    <Text style={styles.bodyFooterLight}>
                        Sources : Editions Langloÿs - Le Barème Rapide® {year}
                    </Text>
                </View>
            </Page>
        </Document>
        // </PDFViewer>
    )
        ;

};

export default GenerateDMTGPdfFile;
