import React, { useEffect, useState } from "react";
import Colors from "../../useclass/colors";
import Pdf1 from "../../medias/avis/liquidation_CSI_dans_donation_demembree_bien_immobilier.pdf";
import Pdf2 from "../../medias/avis/translation_hypotheque.pdf";
import Pdf3 from "../../medias/avis/reforme_droit_des_suretes_et_frais_actes_notaries.pdf";
import Pdf4 from "../../medias/avis/impact_des_recompenses_assiette_de_emolument_acte.pdf";
import Pdf5 from "../../medias/avis/pma_ou_amp.pdf";
import Pdf6 from "../../medias/avis/calcul_assiette_des_droits_enregistrement_en_presence_de_frais_acte_deductibles.pdf";
import Pdf7 from "../../medias/avis/emoluments_de_formalites_principes_generaux_et_zoom.pdf";
import Pdf8 from "../../medias/avis/partage_biens_indivis_et_communaute.pdf";
import Pdf9 from "../../medias/avis/liquidation_participation_aux_acquets.pdf";
import Pdf10 from "../../medias/avis/donation_partage.pdf";
import Pdf11 from "../../medias/avis/partage_de_succession.pdf";
import Pdf12 from "../../medias/avis/comparatif_entre_licitation_et_partage.pdf";

function Avis(props) {
  const styles = {
    avis1: {
      width: 210,
      height: 300,
      margin:20,
      fontSize:18,
      border: "solid 5px white",
      boxShadow: "2px 2px 1px rgba(0,0,0, 0.2)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#ffff",
      padding: 10,
      textAlign: "center",
      textTransform: "uppercase",
    },
    avisCard: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      alignContent: "center",
      alignItems: "center",
    },
    container: {
      padding: 0,
      margin: 0,
      width: "100vw",
      minHeight: "auto",
    },
    card: {
      backgroundColor: Colors.white,
      boxShadow: "2px 2px 1px rgba(0,0,0, 0.2)",
      borderRadius: 5,
      width: "90%",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: 150,
      paddingBottom: 50,
      paddingLeft: 30,
      paddingRight:30,
      minHeight: "30vh",
    },
    cardTitle: {
      fontWeight: "normal",
      color: "#275d83",
      textAlign: "center",
      lineHeight: "1.4em",
      paddingTop: 20,
      marginTop: 5,
      marginBottom: 30,
    },
    cardSubTitle: {
      fontSize: 18,
      marginLeft: 35,
    },
    listElem: {
      lineHeight: 2.3,
      cursor: "pointer",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <h1 style={styles.cardTitle}>Avis d'Experts</h1>
        <div style={styles.avisCard}>
          <div
            style={{ ...styles.avis1, backgroundColor: "#0a354f" }}
            onClick={() => window.open(Pdf1, "_blank")}
          >
            La liquidation de la CSI dans une donation démembrée d’un bien
            immobilier
          </div>
          <div
            style={{ ...styles.avis1, backgroundColor: "#1e4878" }}
            onClick={() => window.open(Pdf2, "_blank")}
          >
            La translation d’hypothèque
          </div>
          <div
            style={{ ...styles.avis1, backgroundColor: "#2d66ad" }}
            onClick={() => window.open(Pdf3, "_blank")}
          >
            La réforme du droit des suretés et les frais d’acte notariés
          </div>
          <div
            style={{ ...styles.avis1, backgroundColor: "#047cbf" }}
            onClick={() => window.open(Pdf4, "_blank")}
          >
            L’impact des récompenses dans l’assiette de l’émolument d’acte
          </div>
          <div
            style={{ ...styles.avis1, backgroundColor: "#024658" }}
            onClick={() => window.open(Pdf5, "_blank")}
          >
            La Procréation Médicalement Assistée (PMA) ou Assistance Médicale à
            la Procréation (AMP) : quelle taxe pour quel acte ?
          </div>
          <div
            style={{ ...styles.avis1, backgroundColor: "#006a84" }}
            onClick={() => window.open(Pdf6, "_blank")}
          >
            Le calcul de l’assiette des droits d’enregistrement en présence de
            frais d’acte déductibles
          </div>
          <div
            style={{ ...styles.avis1, backgroundColor: "#007d8a" }}
            onClick={() => window.open(Pdf7, "_blank")}
          >
            Les émoluments de formalités – Principes généraux et Zoom sur le n°
            204 du Tableau 5
          </div>
          <div
            style={{ ...styles.avis1, backgroundColor: "#009db4" }}
            onClick={() => window.open(Pdf8, "_blank")}
          >
            Partage de biens indivis et de communauté : calcul de l’émolument
            d’acte
          </div>
          <div
            style={{ ...styles.avis1, backgroundColor: "#5ab6aa" }}
            onClick={() => window.open(Pdf9, "_blank")}
          >
            Liquidation d’une participation aux acquêts : principes applicables
            à l’émolument d’acte
          </div>
          <div
            style={{ ...styles.avis1, backgroundColor: "#389c8f" }}
            onClick={() => window.open(Pdf10, "_blank")}
          >
            Donation-partage : quelques points sous forme de questions /
            réponses
          </div>
          <div
            style={{ ...styles.avis1, backgroundColor: "#008682" }}
            onClick={() => window.open(Pdf11, "_blank")}
          >
            Partage de succession - Focus sur l’émolument d’acte et le droit de
            partage
          </div>
          <div
            style={{ ...styles.avis1, backgroundColor: "#00655e" }}
            onClick={() => window.open(Pdf12, "_blank")}
          >
            Indivision - tableau comparatif entre licitation et partage
          </div>
        </div>
      </div>
    </div>
  );
}

export default Avis;
